import moment from "moment-timezone";
import { TENANT_1001, TENANT_1002, TENANT_1003, TENANT_1005, TENANT_D101, TENANT_d101 } from "./ALL/tenantUtils";

export const DATE_FORMAT_1 = "YYYY-MM-DD";
export const DATE_FORMAT_2 = "YYYY/MM/DD";
export const DATE_FORMAT_3 = "YYYY/MM/DD HH:mm";
export const DATE_FORMAT_4 = "DD MMMM YYYY HH:mm";
export const DATE_FORMAT_5 = "HH:mm:ss";
export const DATE_FORMAT_6 = "DD/MM/YYYY HH:mm:ss";
export const DATE_FORMAT_7 = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT_8 = "YYYY.MM.DD.HH.mm.ss";

export const DATE_CREATED = "Date Created";
export const DATE_CREATED_AEST = "Date Created [AEST]";
export const DATE_CREATED_AEDT = "Date Created [AEDT]";
export const DATE_CREATED_UTC = "Date Created [UTC]";

export const AEDT = "AEDT";
export const AEST = "AEST";

export const getCurrentAuTime = () => {
  return moment.tz("Australia/Sydney");
};

export const getCurrentAuTimeAndFormat = (format) => {
  return getCurrentAuTime().format(format);
};

export const isAuSydneyInDST = () => {
  return moment.tz("Australia/Sydney").isDST();
};

export const determineIfAestOrAedt = () => {
  if (getCurrentAuTime().isDST()) {
    return AEDT;
  } else {
    return AEST;
  }
};

/**
 * use this function for formatting a date time, disregarding there time zone.
 * @param {string} dateTime
 * @param {string} format
 * @returns formatted date time string
 */
export const formatDateTimeTypeA = (dateTime, format) => {
  return moment.utc(dateTime).utc(true).format(format);
};

export const convertUtcDateTimeToAest = (dateTime) => {
  return moment(dateTime).utc(false).add(10, "hours");
};

export const convertUtcDateTimeToAestAndFormat = (dateTime, format) => {
  return convertUtcDateTimeToAest(dateTime).format(format);
};

export const convertUtcDateTimeToAedt = (dateTime) => {
  return moment(dateTime).utc(false).add(11, "hours");
};

export const convertUtcDateTimeToAedtAndFormat = (dateTime, format) => {
  return convertUtcDateTimeToAedt(dateTime).format(format);
};

export const tenantLocalTime = (tenantId) => {
  let time;
  if (tenantId === TENANT_1001 || tenantId === TENANT_1002 || tenantId === TENANT_D101 || tenantId === TENANT_d101) {
    time = moment().tz("Australia/Sydney").utc(true);
  } else if (tenantId === TENANT_1005) {
    time = moment().tz("Australia/Brisbane").utc(true);
  } else if (tenantId === TENANT_1003) {
    time = moment().tz("Pacific/Auckland").utc(true);
  }
  return time.toISOString();
};
