import { useRef, useState } from "react";
import {
  Form,
  Spinner,
  Card,
  Row,
  Col,
  Container,
  Button,
} from "react-bootstrap";
import SearchButton from "../Common/ReusableComponents/SearchButton/SearchButton";
import {
  AU_STATES,
  FLAT_TYPES,
  FLOOR_TYPES,
  STREET_TYPES,
} from "./utils/CTSearchViewUtils";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../utils/apiUtils";
import { TENANT_D101, determineTenantNamePassId } from "../../utils/ALL/tenantUtils";

const NMIType1SearchView = ({ token, userId }) => {
  const nmiType1FormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [responseData, setResponseData] = useState({});

  // Parameters
  const [jurisdictionCode, setJurisdictionCode] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [floorOrLevelNumber, setFloorOrLevelNumber] = useState("");
  const [flatOrUnitNumber, setFlatOrUnitNumber] = useState("");
  const [buildingOrPropertyName, setBuildingOrPropertyName] = useState("");
  const [floorOrLevelType, setFloorOrLevelType] = useState("");
  const [flatOrUnitType, setFlatOrUnitType] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetType, setStreetType] = useState("");
  const [stateOrTerritory, setStateOrTerritory] = useState("");
  const [locationDescriptor, setLocationDescriptor] = useState("");
  const [houseNumberSuffix, setHouseNumberSuffix] = useState("");
  const [streetSuffix, setStreetSuffix] = useState("");
  const [suburbOrPlaceOrLocality, setSuburbOrPlaceOrLocality] = useState("");
  const [postcode, setPostcode] = useState("");

  // Modal Results
  const [showResults, setShowResults] = useState(false);
  const [nmiResult, setNMIResult] = useState(false);
  const [checksum, setChecksum] = useState(false);

  const handleResultsShow = (nmiResult, checksum) => {
    setNMIResult(nmiResult);
    setChecksum(checksum);
  };

  const hanleNmiDiscoverType1 = async (e) => {
    e.preventDefault();

    try {
      const params = {
        transactionId: "mdh-dev-nmi-discovery1",

        jurisdictionCode: jurisdictionCode,
        lotNumber: lotNumber,
        floorOrLevelNumber: floorOrLevelNumber,
        flatOrUnitNumber: flatOrUnitNumber,
        buildingOrPropertyName: buildingOrPropertyName,
        floorOrLevelType: floorOrLevelType,
        flatOrUnitType: flatOrUnitType,
        houseNumber: houseNumber,
        streetName: streetName,
        streetType: streetType,
        stateOrTerritory: stateOrTerritory,
        locationDescriptor: locationDescriptor,
        houseNumberSuffix: houseNumberSuffix,
        streetSuffix: streetSuffix,
        suburbOrPlaceOrLocality: suburbOrPlaceOrLocality,
        postcode: postcode,
      };

      console.log(params);
      setIsLoading(true);
      setShowResults(true);

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/nmid-type1`,
        params
      );

      console.log(postResponse);

      if (postResponse.status === HTTP_STATUS_CODE_200) {
        setIsFailedToRetrieveData(false);
        setResponseData(postResponse.data.data);
        handleResultsShow(postResponse.data.nmi, postResponse.data.checksum);
        setIsLoading(false);
        console.log(responseData);
      } else if (postResponse.status === HTTP_STATUS_CODE_400) {
        console.log("Got 400 status code from API");
        setErrorMessage("No NMI Results returned");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        console.log("API responded with 504 code");
        setErrorMessage("Taking too long to process the request");
        setIsFailedToRetrieveData(true);
      } else {
        console.log("Unhandled http status code");
        setErrorMessage("Unhandled Error");
        setIsFailedToRetrieveData(true);
      }
    } catch (error) {
      setIsLoading(false);
      setIsFailedToRetrieveData(true);
    }
  };

  return (
    <div>
      <Container
        fluid
        className="master-txn-container-wrapper"
        style={{ display: showResults ? "block" : "none" }}
      >
        {/* Outer Card */}
        <Row>
          <Col className="search-results-title" id="kaluza">
            Search Results
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <div className="wrapper-div">
            {isLoading ? (
              <div className="spinner-div-searching">
                <Spinner
                  animation="border"
                  variant="danger"
                  className="spinner-searching"
                />
              </div>
            ) : isFailedToRetrieveData ? (
              <p>No Results Found.</p>
            ) : (
              <Row>
                <Col>
                  <p>
                    <strong>NMI:</strong> {nmiResult}
                  </p>
                  <p>
                    <strong>Checksum:</strong> {checksum}
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </Row>
      </Container>

      <Container fluid className="master-txn-container-wrapper">
        {/* Outer Card */}
        <Row>
          {/* <Col className="search-results-title" id="kaluza">
            Electricity - NMI Discovery Type 1
          </Col> */}
          <Col className="search-results-title" id={determineTenantNamePassId(TENANT_D101)}>
            NMI Discovery
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <div className="wrapper-div">
            {/* Inner Card */}
            <div className={`kaluza-nmi-discovery-type-3-search-title`}>
              Address
            </div>
            <div className="search-result-div">
              <Form ref={nmiType1FormRef}>
                {/* <div className="row">
                  <div className="col-md-6"> */}
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Jurisdiction Code</Form.Label>
                      <Form.Select
                        name="jurisdictionCode"
                        onChange={(e) => setJurisdictionCode(e.target.value)}
                        value={jurisdictionCode}
                      >
                        <option value="AAT" disabled>
                          AAT
                        </option>
                        {AU_STATES.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Lot Number</Form.Label>
                      <Form.Control
                        name="lotNumber"
                        type="text"
                        onChange={(e) => setLotNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Building/Property Name</Form.Label>
                      <Form.Control
                        name="buildingOrPropertyName"
                        type="text"
                        onChange={(e) =>
                          setBuildingOrPropertyName(e.target.value)
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Floor Number</Form.Label>
                      <Form.Control
                        name="floorNumber"
                        type="text"
                        onChange={(e) => setFloorOrLevelNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Floor Type</Form.Label>
                      <Form.Select
                        name="floorType"
                        onChange={(e) => setFloorOrLevelType(e.target.value)}
                        value={floorOrLevelType}
                      >
                        <option disabled></option>
                        {FLOOR_TYPES.map((option) => (
                          <option key={option.key} value={option.key}>
                            {option.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <Form.Group>
                      <Form.Label>Flat Number</Form.Label>
                      <Form.Control
                        name="flatNumber"
                        type="text"
                        onChange={(e) => setFlatOrUnitNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Flat Type</Form.Label>
                      <Form.Select
                        name="flatType"
                        onChange={(e) => setFlatOrUnitType(e.target.value)}
                        value={flatOrUnitType}
                      >
                        <option disabled></option>
                        {FLAT_TYPES.map((option) => (
                          <option key={option.key} value={option.key}>
                            {option.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>House Number</Form.Label>
                      <Form.Control
                        name="houseNumber"
                        type="text"
                        onChange={(e) => setHouseNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>House Number Suffix</Form.Label>
                      <Form.Control
                        name="houseNumberSuffix"
                        type="text"
                        onChange={(e) => setHouseNumberSuffix(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Street Name</Form.Label>
                      <Form.Control
                        name="streetname"
                        type="text"
                        onChange={(e) => setStreetName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Street Suffix</Form.Label>
                      <Form.Control
                        name="streetSuffix"
                        type="text"
                        onChange={(e) => setStreetSuffix(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Street Type</Form.Label>
                      <Form.Select
                        name="streetType"
                        onChange={(e) => setStreetType(e.target.value)}
                        value={streetType}
                      >
                        {STREET_TYPES.map((option) => (
                          <option key={option.key} value={option.key}>
                            {option.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label>Street Type</Form.Label>
                      <Form.Control
                        name="streetType"
                        type="text"
                        onChange={(e) =>
                          setStreetType(e.target.value.toUpperCase())
                        }
                      ></Form.Control>
                    </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Suburb/Locality</Form.Label>
                      <Form.Control
                        name="suburbOrPlaceOrLocality"
                        type="text"
                        onChange={(e) =>
                          setSuburbOrPlaceOrLocality(e.target.value)
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>State Or Territory</Form.Label>
                      <Form.Select
                        name="stateOrTerritory"
                        onChange={(e) => setStateOrTerritory(e.target.value)}
                        value={stateOrTerritory}
                      >
                        <option value="AAT" disabled>
                          AAT
                        </option>
                        {AU_STATES.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    {" "}
                    <Form.Group>
                      <Form.Label>Postcode</Form.Label>
                      <Form.Control
                        name="postCode"
                        type="text"
                        onChange={(e) => setPostcode(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Location Descriptor</Form.Label>
                      <Form.Control
                        name="locationDescriptor"
                        type="text"
                        onChange={(e) => setLocationDescriptor(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>

                {/* </div> */}
                {/* <div className="col-md-6"> */}

                {/* </div>
                </div> */}
                {/* <Form.Group className="search-button-div"> */}
                {/* <Form.Group className="search-button-div">
                  <Button
                    type="submit"
                    className="mt-2 search-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      nmiType1FormRef.current.reset();
                    }}
                    disabled={false}
                  >
                    Clear
                  </Button>
                </Form.Group> */}
                <SearchButton
                  onClick={hanleNmiDiscoverType1}
                  isDisabled={false}
                ></SearchButton>
                {/* </Form.Group> */}
              </Form>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NMIType1SearchView;
