import { useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import {
  TENANT_D101,
  determineTenantNamePassId,
} from "../../utils/ALL/tenantUtils";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../utils/apiUtils";
import SearchButton from "../Common/ReusableComponents/SearchButton/SearchButton";

import { log } from "../../utils/ALL/loggingUtils";
import "./NMIType3Search.css";
import { computeNmiChecksum } from "./NMIType3SearchUtils";
import { createNmiData } from "../StandingData/result/NMIDataUtils";

const NMIType3SearchView = ({ token, userId }) => {
  const [nmi, setNmi] = useState("");
  const [checksum, setChecksum] = useState("");
  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const [nmiData, setNmiData] = useState({});

  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleNmiDiscoverType2 = async (e) => {
    e.preventDefault();

    try {
      const params = {
        nmi: nmi,
        checksum: checksum,
      };
      console.log(params);
      setShowResults(false);
      setIsLoading(true);

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/nmid-type3`,
        params
      );

      console.log(postResponse);

      setIsLoading(false);
      setShowResults(true);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("No XML returned");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setResponseData(postResponse.data.data);
        setNmiData(createNmiData(postResponse.data.data));
        setIsFailedToRetrieveData(false);
        setIsLoading(false);
        setShowResults(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        log("API responded with 504 code");
        setErrorMessage("Taking too long to process the request");
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setErrorMessage("Unhandled Error");
        setIsFailedToRetrieveData(true);
      }
    } catch (error) {
      setIsLoading(false);
      setShowResults(false);
    }
  };
  const handleNMIChange = (e) => {
    const nmi = e.target.value;
    setNmi(nmi);
    const checksum = computeNmiChecksum(nmi);
    setChecksum(checksum);
  };
  return (
    <div
      // className="background-image-wrapper"
      id={determineTenantNamePassId(TENANT_D101)}
    >
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col
            className="search-results-title"
            id={determineTenantNamePassId(TENANT_D101)}
          >
            {/* Electricity - NMI Discovery Type 3 */}
            Electricity - NMI Standing Data
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="wrapper-div">
            <div
              className={`${determineTenantNamePassId(
                TENANT_D101
              )}-nmi-discovery-type-3-search-title`}
            >
              {/* NMI Discovery Type 3 */}
              NMI Standing Data
            </div>
            <div className="search-result-div">
              <Form>
                <Form.Group>
                  <Form.Label>NMI</Form.Label>
                  <Form.Control
                    name="nmi"
                    type="text"
                    onChange={handleNMIChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Checksum</Form.Label>
                  <Form.Control
                    disabled={true}
                    name="checksum"
                    type="text"
                    value={checksum}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="search-button-div">
                  <SearchButton
                    onClick={handleNmiDiscoverType2}
                    isDisabled={false}
                  ></SearchButton>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Row>

        {isLoading && (
          <div className="spinner-div-searching">
            <Spinner
              animation="border"
              variant="danger"
              className="spinner-searching"
            />
          </div>
        )}
        {showResults ? (
          <Row
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="wrapper-div">
              <div
                className={`${determineTenantNamePassId(
                  TENANT_D101
                )}-nmi-discovery-type-3-search-title`}
              >
                Standing Data Results
              </div>
              <div className="search-result-div">
                {!isFailedToRetrieveData ? (
                  <Table>
                    <tr>
                      <td>
                        <strong>NMI:</strong> {nmi}
                      </td>
                      <td>
                        <strong>Start Date: {nmiData.fromDate}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Classification Code:</strong>{" "}
                        {nmiData.nmiClassificationCode}
                      </td>
                      <td>
                        <strong>NMI Status Code:</strong> {nmiData.nmiStatus}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Embedded Network ID (Parent):</strong>
                      </td>
                      <td>
                        <strong>Jurisdiction:</strong>{" "}
                        {nmiData.jurisdictionCode}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong> Embedded Network ID (Child):</strong>
                      </td>
                      <td>
                        <strong>Address:</strong>
                        {nmiData.flatOrUnitNumber
                          ? `${nmiData.flatOrUnitNumber}/`
                          : ""}
                        {nmiData.houseNumber} {nmiData.streetName}{" "}
                        {nmiData.streetType} {nmiData.suburbOrPlaceOrLocality}{" "}
                        {nmiData.stateOrTerritory} {nmiData.postCode}
                      </td>
                    </tr>
                  </Table>
                ) : (
                  <div>{errorMessage}</div>
                )}
              </div>
            </div>
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default NMIType3SearchView;
