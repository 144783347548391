export const computeNmiChecksum = (nmi) => {
  if (!nmi) {
    return "";
  } else if (nmi.length === 11) {
    return nmi.slice(-1);
  } else {
    // base from https://www.aemo.com.au/-/media/files/electricity/nem/retail_and_metering/metering-procedures/2018/msats-national-metering-identifier-procedure.pdf?la=en&hash=243AA41A6FB95B4050E0B260EE56ED31#:~:text=NMI%20CHECKSUM,-(a)%20To%20reduce&text=(i)%20Double%20the%20ASCII%20value,next%20highest%20multiple%20of%2010.

    // Initialize the checksum register

    // Iterate through each character of the string
    let reversedNmiAsciiMap = [];

    //converting the digits to its ascii value and reversing the array
    for (let i = 0; i < nmi.length; i++) {
      // Convert the character to its ASCII value
      const asciiValue = nmi.charCodeAt(i);
      console.log("nmi digit value: " + nmi[i] + " ascii value: " + asciiValue);

      reversedNmiAsciiMap = [asciiValue, ...reversedNmiAsciiMap];
    }

    console.log(reversedNmiAsciiMap);
    let nmiAsciiArrayDoubled = [];
    reversedNmiAsciiMap.map((value, index) => {
      if (index % 2 === 0) {
        nmiAsciiArrayDoubled = [...nmiAsciiArrayDoubled, 2 * value];
      } else {
        nmiAsciiArrayDoubled = [...nmiAsciiArrayDoubled, value];
      }
    });
    console.log(nmiAsciiArrayDoubled);

    let total = 0;
    nmiAsciiArrayDoubled.map((value, index) => {
      const numberString = Math.abs(value).toString();
      let digitSum = 0;

      for (let i = 0; i < numberString.length; i++) {
        const digit = parseInt(numberString[i], 10);
        digitSum += digit;
      }

      total = total + digitSum;

      console.log("value to sum: " + value + " sum of digits: " + digitSum);
    });

    console.log("total: " + total);

    //getting the next highest multiple of 10
    const nextHighestMultipleOf10 = Math.ceil(total / 10) * 10;

    console.log("nextHighestMultipleOf10: " + nextHighestMultipleOf10);

    const checksum = nextHighestMultipleOf10 - total;

    return checksum;
  }
};
