import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import ExpiredSessionModal from "./Components/Common/Authentication/ExpiredSessionModal/ExpiredSessionModal";
import FooterSignIn from "./Components/Common/Authentication/FooterSignIn/FooterSignIn";
import HeaderSignIn from "./Components/Common/Authentication/HeaderSignIn/HeaderSignIn";
import SignIn from "./Components/Common/Authentication/SignIn/SignIn";
import Footer from "./Components/Common/Footer/Footer";
import Header from "./Components/Common/Header/Header";
import Unauthorised from "./Components/Common/Unauthorised/Unauthorised";
import UnrecognizedPath from "./Components/Common/UnrecognizedPath/UnrecognizedPath";
import FileUploadHandler from "./Components/FileUpload/FileUploadHandler";
import MasterTxnDetailsViewHandler from "./Components/MasterTransactionDetailsView/MasterTransactionDetailsViewHandlers/MasterTxnDetailsViewHandler";
import NMIRoutingSearchView from "./Components/NMIRoutingSearch/NMIRoutingSearchView";
import RealmsPage from "./Components/RealmsPage/RealmsPage";
import GatewayThroughputHandler from "./Components/Reports/GatewayThroughput/GatewayThroughputHandler";
import DeEnergisationStatusHandler from "./Components/Reports/HealthCheckReport/DeEnergisationStatus/DeEnergisationStatusHandler";
import AUMeteringReports from "./Components/Reports/MeteringData/AU/AUMeteringReport";
import AUServiceOrderReports from "./Components/Reports/ServiceOrderReport/AU/AUServiceOrderReports";
import AUUndeliveredMessageReports from "./Components/Reports/UndeliveredMessageReport/AU/AUUndeliveredReports";
import SearchViewHandler from "./Components/Search/SearchViewHandler";
import {
  ACC_MDH_ACCESS,
  ACC_MDH_Access,
  NPE_MDH_REPORT,
  NPE_MDH_VIEW,
  PORTAL_ACCESS_1002,
  PORTAL_ACCESS_1003,
  PROD_MDH_REPORT,
  PROD_MDH_VIEW,
} from "./utils/ALL/accessGroups";
import { getUserTenantId } from "./utils/ALL/localStorageUtils";
import { log } from "./utils/ALL/loggingUtils";
import {
  TENANT_1001,
  TENANT_1002,
  TENANT_1003,
  TENANT_1004,
  TENANT_1005,
} from "./utils/ALL/tenantUtils";
import SOStatusHandler from "./Components/Reports/HealthCheckReport/SOStatus/SOStatusHandler";
import NMIType1SearchView from "./Components/CreateTransaction/NMIType1SearchView";
import NMIType3SearchView from "./Components/CreateTransaction/NMIType3SearchView";
import D101StandingDataView from "./Components/StandingData/views/D101StandingDataView";
import MoveIn from "./Components/Search/views/MoveIn";
import D101ProcessSearch from "./Components/Search/views/D101ProcessSearch";
import D101ProcessRequestDetailsView from "./Components/ProcessRequestDetails/views/D101ProcessRequestDetailsView";
import POCTextBox from "./Components/CreateTransaction/POCTextBox";
import SORequestReports from "./Components/Reports/ServiceOrderReport/SORequestReports";
import D101TransactionUpload from "./Components/TransactionUpload/D101TransactionUpload";
function App() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [signInPage, setSignInPage] = useState("select-login");
  const [accessGroups, setAccessGroups] = useState([]);
  const TENANT_ID = getUserTenantId();
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data, message } }) => {
      switch (event) {
        case "signIn":
          log("1: signIn");
          getUser().then((userData) => {
            setAuthenticatedUser(userData);
          });
          break;
        case "cognitoHostedUI":
          log("2: cognitoHostedUI");
          getUser().then((userData) => {
            setAuthenticatedUser(userData);
          });
          break;
        case "cognitoHostedUI_failure":
          log("Sign in failure");
          log("cognitoHostedUI_failure");
          break;
        case "signOut":
          log("3: signout");
          setUser(null);
          break;
        case "signIn_failure":
          log("4: signIn_failure");
          if (Error(data).message.includes("NotAuthorizedException")) {
            return alert("Invalid credentials");
          }
          break;
        case "tokenRefresh":
          log("handling tokenRefresh");
          break;
        case "tokenRefresh_failure":
          setIsSessionExpired(true);
          break;
        default:
          log(event + "is not handled");
          break;
      }
    });

    getUser().then((userData) => {
      setAuthenticatedUser(userData);
    });
  }, []);

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        log("Not signed in");
      });
  };

  const setAuthenticatedUser = (userData) => {
    // log(userData);
    setUserId(userData.username);
    setUser(userData);

    if (userData != null) {
      /** Check  "custom:group" it might change for other clients*/
      let customGroupString =
        userData.signInUserSession.idToken.payload["custom:group"];

      if (customGroupString) {
        // Check if custom:groups attribute is in array type
        if (
          customGroupString.charAt(0) === "[" &&
          customGroupString.charAt(customGroupString.length - 1) === "]"
        ) {
          customGroupString = customGroupString.slice(
            1,
            customGroupString.length - 1
          );
        }

        let customGroupArray = customGroupString.split(", ");
        // log(customGroupArray);

        setAccessGroups(customGroupArray);
      } else {
        //when using cognito:groups the value is already an array
        customGroupString =
          userData.signInUserSession.idToken.payload["cognito:groups"];
        setAccessGroups(customGroupString);
      }

      // log(customGroupString);
    }
  };

  const determineSignPage = () => {
    switch (signInPage.toLowerCase()) {
      case "select-login":
        return (
          <>
            <RealmsPage setSignInPage={setSignInPage}></RealmsPage>
          </>
        );
      case "user-pass-login":
        return (
          <>
            <div className="div-sign-in-wrapper">
              <HeaderSignIn />
              <SignIn setSignInPage={setSignInPage} />
              <FooterSignIn />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const isUserAllowedToViewReportsTab = () => {
    let tenantId = getUserTenantId();
    if (tenantId === TENANT_1001) {
      //Add validation for origin users
      return (
        accessGroups.includes(ACC_MDH_ACCESS) ||
        accessGroups.includes(ACC_MDH_Access) ||
        accessGroups.includes(NPE_MDH_REPORT) ||
        accessGroups.includes(PROD_MDH_REPORT)
      );
    } else if (tenantId === TENANT_1002) {
      return false;
    } else if (tenantId === TENANT_1005) {
      return true;
    } else {
      return false;
    }
  };

  const isUserAllowedToViewSearchTab = () => {
    if (TENANT_ID === TENANT_1001) {
      return (
        accessGroups.includes(ACC_MDH_ACCESS) ||
        accessGroups.includes(ACC_MDH_Access) ||
        accessGroups.includes(NPE_MDH_VIEW) ||
        accessGroups.includes(PROD_MDH_VIEW)
      );
    } else if (
      TENANT_ID === TENANT_1002 ||
      TENANT_ID === TENANT_1003 ||
      TENANT_ID === TENANT_1005 ||
      TENANT_ID === "D101"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isAllowedToViewPortal = () => {
    // if there are no data for accessGroups it will return false
    if (accessGroups) {
      switch (TENANT_ID) {
        case TENANT_1001:
          return (
            accessGroups.includes(ACC_MDH_ACCESS) ||
            accessGroups.includes(ACC_MDH_Access) ||
            accessGroups.includes(NPE_MDH_VIEW) ||
            accessGroups.includes(PROD_MDH_VIEW)
          );
        case TENANT_1002:
          return (
            accessGroups.includes(PORTAL_ACCESS_1002) ||
            accessGroups.includes(ACC_MDH_ACCESS)
          );
        case TENANT_1003:
          return (
            accessGroups.includes(PORTAL_ACCESS_1003) ||
            accessGroups.includes(ACC_MDH_ACCESS)
          );
        case TENANT_1005:
          return true;
        case "D101":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const allowedTenantRoutes = () => {
    const tenantId = getUserTenantId();
    switch (tenantId) {
      case TENANT_1001:
        return (
          <>
            {isUserAllowedToViewReportsTab ? (
              <>
                <Route
                  path="/de-energisation-status"
                  exact
                  element={<DeEnergisationStatusHandler />}
                ></Route>
                <Route
                  path="/so-status"
                  exact
                  element={<SOStatusHandler />}
                ></Route>
                <Route
                  path="/so-report"
                  exact
                  element={<AUServiceOrderReports />}
                ></Route>
                <Route
                  path="/undelivered-messages"
                  exact
                  element={<AUUndeliveredMessageReports />}
                ></Route>
                <Route
                  path="/metering-data"
                  exact
                  element={<AUMeteringReports />}
                ></Route>
              </>
            ) : null}
          </>
        );

      case TENANT_1002:
        return null;
      case TENANT_1003:
        return (
          <Route
            path="/file-upload"
            exact
            element={<FileUploadHandler />}
          ></Route>
        );
      case TENANT_1004:
        return null;
      case TENANT_1005:
        return (
          <>
            {isUserAllowedToViewSearchTab ? (
              <Route
                path="/nmi-routing-search"
                exact
                element={<NMIRoutingSearchView />}
              ></Route>
            ) : null}
            {isUserAllowedToViewReportsTab ? (
              <Route
                path="/gateway-throughput"
                exact
                element={<GatewayThroughputHandler />}
              ></Route>
            ) : null}
          </>
        );

      default:
        return null;
    }
  };
  const isAllowedToCreateTransaction = () => {
    const tenantId = getUserTenantId();

    return tenantId === "D101";
  };
  const isAllowedToSearchStandingData = () => {
    const tenantId = getUserTenantId();

    return tenantId === "D101";
  };
  // log("user: " + userId);
  return (
    <>
      {user ? (
        <>
          {isAllowedToViewPortal() ? (
            <>
              <Header
                accessGroups={accessGroups}
                isUserAllowedToViewReportsTab={isUserAllowedToViewReportsTab}
                isUserAllowedToViewSearchTab={isUserAllowedToViewSearchTab}
                isAllowedToCreateTransaction={isAllowedToCreateTransaction}
              ></Header>
              <Routes>
                {/** Common path for all tenants */}
                <Route
                  path="/"
                  exact
                  element={<Navigate to={"/search"} />}
                ></Route>
                {isUserAllowedToViewSearchTab() ? (
                  <>
                    <Route
                      path="/search"
                      exact
                      element={
                        <SearchViewHandler
                          token={accessGroups}
                          userId={userId}
                        />
                      }
                    ></Route>
                    <Route
                      path="/masterTxnDetails"
                      element={<MasterTxnDetailsViewHandler />}
                    ></Route>
                  </>
                ) : null}

                {isAllowedToCreateTransaction() ? (
                  <Route
                    path="/nmi-type-1"
                    exact
                    element={<NMIType1SearchView></NMIType1SearchView>}
                  />
                ) : null}

                {isAllowedToCreateTransaction() ? (
                  <>
                    <Route
                      path="/nmi-type-3"
                      exact
                      element={<NMIType3SearchView></NMIType3SearchView>}
                    />
                    <Route
                      path="/post-transaction"
                      exact
                      element={<POCTextBox />}
                    />
                  </>
                ) : null}

                {isAllowedToSearchStandingData() ? (
                  <Route
                    path="/standing-data"
                    exact
                    element={<D101StandingDataView></D101StandingDataView>}
                  />
                ) : null}

                <Route
                  path="/move-in"
                  exact
                  element={<MoveIn></MoveIn>}
                />
                <Route
                  path="/process-search"
                  exact
                  element={<D101ProcessSearch></D101ProcessSearch>}
                />

                <Route
                  path="/process-request-details"
                  exact
                  element={
                    <D101ProcessRequestDetailsView></D101ProcessRequestDetailsView>
                  }
                />

                <Route
                  path="/so-request-reports"
                  exact
                  element={<SORequestReports></SORequestReports>}
                />

                <Route
                  path="/transaction-upload"
                  exact
                  element={<D101TransactionUpload />}
                ></Route>

                {/** Handle path that is not declared */}
                <Route
                  path="*"
                  exact
                  element={<UnrecognizedPath />}
                ></Route>
              </Routes>
              <Footer></Footer>
            </>
          ) : (
            <Unauthorised></Unauthorised>
          )}
        </>
      ) : (
        <div className="div-sign-in-wrapper">
          <HeaderSignIn />
          <SignIn />
          <FooterSignIn />
        </div>
      )}
      {isSessionExpired ? (
        <ExpiredSessionModal isShowModal={true}></ExpiredSessionModal>
      ) : null}
    </>
  );
}

export default App;
//
