import React, { useEffect, useState } from "react";
import {
  TENANT_D101,
  determineTenantNamePassId,
} from "../../../utils/ALL/tenantUtils";
import {
  Button,
  Col,
  Container,
  // ProgressBar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "./D101ProcessRequestDetailsView.css";
import { Checkmark } from "react-checkmark";
import { CircleFill } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import { log } from "../../../utils/ALL/loggingUtils";
import { masterTransactionIdFormatter } from "../../SearchResults/NMISearchResults/utils/NMISearchResultUtils";
import {
  MASTER_TXN_STATUS_COM,
  auMasterTxnStatusFormatter,
} from "../../../utils/AU/auTxnStatusUtils";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import { Step, ProgressBar } from "react-step-progress-bar";
// import "react-step-progress-bar/styles.css";
// import { StepStates } from "react-step-progress/dist/models";
const D101ProcessRequestDetailsView = () => {
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [masterTxnDetails, setMasterTxnDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [masterTransactionResults, setMasterTransactionResults] = useState([]);
  const [isApiError, setIsApiError] = useState(false);
  useEffect(() => {
    fetchAssociatedTransactions();
  }, []);

  const fetchAssociatedTransactions = async () => {
    try {
      setIsFetchingData(true);
      setMasterTxnDetails([]);
      let params = {
        requestId: urlParams.requestId,
      };
      console.log(params);

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/process-requestid-mastertxn-id`,
        params
      );
      // console.log(postResponse);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("Error while processing request");
        setIsFailedToRetrieveData(true);
        setMasterTransactionResults([]);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setMasterTransactionResults(postResponse.data);
        log(postResponse.data);
        setIsFailedToRetrieveData(false);
        setErrorMessage("");
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setMasterTransactionResults([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setMasterTransactionResults([]);
        setIsFailedToRetrieveData(true);
      }
      setIsFetchingData(false);
    } catch (err) {
      setMasterTransactionResults([]);
      setIsFetchingData(false);
      console.error(err);
    }
  };

  const determineJsonToUse = (txnType) => {
    let jsonToUse = {};

    for (let index = 0; index < masterTransactionResults.length; index++) {
      const masterTransactionResult = masterTransactionResults[index];

      if (masterTransactionResult.masterTxnType === txnType) {
        jsonToUse = masterTransactionResult;
        break;
      }
    }

    return jsonToUse;
  };

  const createViewConversation = (txnName, txnType) => {
    const jsonToUse = determineJsonToUse(txnType);

    let isTransactionCOM = jsonToUse.status === MASTER_TXN_STATUS_COM;

    if (jsonToUse.masterTxnType) {
      return (
        <>
          {/* <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {masterTransactionIdFormatter(
              "View Conversation",
              jsonToUse,
              null,
              {
                tenantId: TENANT_D101,
              }
            )}
          </div>
          <div>{auMasterTxnStatusFormatter(jsonToUse.status)}</div> */}
          <div>
            {isTransactionCOM ? (
              <Checkmark></Checkmark>
            ) : (
              <CircleFill
                style={{
                  width: "52px",
                  height: "52px",
                  color: "var(--common-blue-3)",
                }}
              ></CircleFill>
            )}
          </div>
          <div>
            <strong>{txnName}</strong>
          </div>
          <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {masterTransactionIdFormatter(
              "View Conversation",
              jsonToUse,
              null,
              {
                tenantId: TENANT_D101,
              }
            )}
          </div>
          <div>{auMasterTxnStatusFormatter(jsonToUse.status)}</div>
        </>
      );
    } else {
      return (
        <>
          {/* <div>
            <CircleFill
              style={{ width: "52px", height: "52px" }}
              color="black"
            ></CircleFill>
          </div>
          <div>
            <strong>{txnName}</strong>
          </div>
          <div>Transaction is still pending</div>
          <div></div> */}
          <div>
            <CircleFill
              style={{ width: "52px", height: "52px" }}
              color="black"
            ></CircleFill>
          </div>
          <div>
            <strong>{txnName}</strong>
          </div>
          <div>Transaction is still pending</div>
          <div></div>
        </>
      );
    }
  };

  return (
    // TODO: check if the the MasterTxnDetailsViewWrapper can be used or be modified
    <div
      className="background-image-wrapper-kaluza"
      id={determineTenantNamePassId(TENANT_D101)}
    >
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col
            className="search-results-title"
            id={determineTenantNamePassId(TENANT_D101)}
          >
            Process Request Details - Move-In
          </Col>
        </Row>
        {!isFetchingData && isApiError ? (
          <Row className="spinner-div master-txn-view-spinner-wrapper">
            Error on retrieving data.
          </Row>
        ) : (
          <>
            {isFetchingData ? (
              <Row className="spinner-div master-txn-view-spinner-wrapper">
                <Col className="spinner-col">
                  <Spinner
                    animation="border"
                    variant="danger"
                    className="spinner-master-txn-viewer"
                  />
                </Col>
              </Row>
            ) : (
              <>
                {/* {masterTransactionResults.length > 0 ? ( */}
                <Row
                  className="master-transaction-row master-txn-wrapper-child"
                  data-test="master-transaction-component"
                >
                  <Col>
                    {/* <Table borderless={true}>
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td style={{ width: "15%" }}>
                            <CircleFill
                              style={{ width: "52px", height: "52px" }}
                              color="black"
                            ></CircleFill>
                          </td>
                          <td
                            style={{ width: "27.5%", backgroundColor: "green" }}
                          ></td>
                          <td style={{ width: "15%" }}>
                            <CircleFill
                              style={{ width: "52px", height: "52px" }}
                              color="black"
                            ></CircleFill>
                          </td>
                          <td
                            style={{ width: "27.5%", backgroundColor: "black" }}
                          ></td>
                          <td style={{ width: "15%" }}>
                            <CircleFill
                              style={{ width: "52px", height: "52px" }}
                              color="black"
                            ></CircleFill>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </Table> */}

                    <Table style={{ textAlign: "center" }}>
                      <tr>
                        <td style={{ width: "15%" }}>
                          {createViewConversation(
                            "Change Request",
                            "ChangeRequest"
                          )}
                        </td>
                        <td style={{ width: "27.5%" }}>
                          <div
                            style={{
                              backgroundColor: "black",
                              alignContent: "center",
                            }}
                          ></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </td>
                        <td style={{ width: "15%" }}>
                          {createViewConversation(
                            "Service Order",
                            "ServiceOrder"
                          )}
                        </td>
                        <td style={{ width: "27.5%" }}>
                          <div style={{ backgroundColor: "black" }}></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </td>
                        <td style={{ width: "15%" }}>
                          {createViewConversation(
                            "Customer Details Notification",
                            "CustomerDetails"
                          )}
                        </td>
                      </tr>
                    </Table>
                    {/* <StepProgressBar
                      buttonWrapperClass="progress-bar-button-wrapper-class"
                      startingStep={0}
                      steps={[
                        {
                          label: "Change Request",
                          name: "Change Request",
                          subtitle: createViewConversation(
                            "Change Request",
                            "ChangeRequest"
                          ),
                          state: "completed",
                          // content: createViewConversation(
                          //   "Change Request",
                          //   "ChangeRequest"
                          // ),
                        },
                        {
                          label: "Service Order",
                          name: "Service Order",
                          state: "current",
                          // content: <div>data for Service Order</div>,
                        },
                        {
                          label: "Customer Details Notification",
                          name: "Customer Details Notification",
                          // content: <div>data for Service Order</div>,
                        },
                      ]}
                    ></StepProgressBar> */}
                    {/* <ProgressBar
                      percent={75}
                      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                    >
                      <Step transition="scale">
                      
                        {({ accomplished }) => (
                          <div>lol</div>
                          // <img
                          //   style={{
                          //     filter: `grayscale(${accomplished ? 0 : 80}%)`,
                          //   }}
                          //   width="30"
                          //   src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/9d/Pichu.png/revision/latest?cb=20170407222851"
                          // />
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div>lol</div>
                          // <img
                          //   style={{
                          //     filter: `grayscale(${accomplished ? 0 : 80}%)`,
                          //   }}
                          //   width="30"
                          //   src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/97/Pikachu_%28Smiling%29.png/revision/latest?cb=20170410234508"
                          // />
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div>lol</div>
                          // <img
                          //   style={{
                          //     filter: `grayscale(${accomplished ? 0 : 80}%)`,
                          //   }}
                          //   width="30"
                          //   src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/97/Pikachu_%28Smiling%29.png/revision/latest?cb=20170410234508"
                          // />
                        )}
                      </Step>
                    </ProgressBar> */}
                    {/* <StepProgressBar
                      buttonWrapperClass="progress-bar-button-wrapper-class"
                      startingStep={0}
                      stepClass={"step-class"}
                      steps={[
                        {
                          label: "Change Request",
                          name: "Change Request",
                          state: "completed",
                        },
                        {
                          label: "Service Order",
                          name: "Service Order",
                        },
                        {
                          label: "CDN",
                          name: "CDN",
                        },
                      ]}
                    /> */}
                  </Col>
                </Row>
                {/* ) : (
                  <div>transaction is still pending</div>
                )} */}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default D101ProcessRequestDetailsView;
