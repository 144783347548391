import React, { useEffect, useState } from 'react'
import { TENANT_D101, TENANT_ID_COUNTRY_MAP, determineTenantNamePassId } from '../../utils/ALL/tenantUtils'
import MasterTxnDetailsViewWrapper from '../MasterTransactionDetailsView/wrappers/MasterTxnDetailsViewWrapper'
import { Alert, Button, Col, Container, Form, Nav, Row, Spinner } from 'react-bootstrap'
import SearchButton from '../Common/ReusableComponents/SearchButton/SearchButton'
import { log } from '../../utils/ALL/loggingUtils'
import { HTTP_STATUS_CODE_200, HTTP_STATUS_CODE_400, HTTP_STATUS_CODE_504, doPost1Try } from '../../utils/apiUtils'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import "./POCTextBox.css"
const POCTextBox = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false)
  const [xml, setXml] = useState("")
  const [messageId, setMessageId] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });
  const location = useLocation();

  const [isInvalidXml, setIsInvalidXml] = useState(false)
  const txnTypeMap = new Map([
    ["so-request", "Service Order Request"],
    ["so-response", "Service Order Response"],
  ])

  const [documentType, setDocumentType] = useState("");
  const [json, setJson] = useState("")
  const [isInvalidJson, setIsInvalidJson] = useState(false)
  const [title, setTitle] = useState("")
  const [activeNavTab, setActiveNavTab] = useState(0)
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    //convert the search params to an object
    const paramsObject = {};
    for (const [key, value] of searchParams) {
      paramsObject[key] = value;
    }

    setTitle(txnTypeMap.get(paramsObject.txnType));
    setDocumentType(paramsObject.document);

    if (paramsObject.document === "xml") {
      setActiveNavTab(0)
    } else if (paramsObject.document === "json") {
      setActiveNavTab(1)
    } else {
      setActiveNavTab(99)
    }

  }, [location.search])

  const handleJsonSubmit = async (event) => {

    event.preventDefault();

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);
      setShowSuccessAlert(false)

      let params = {
        body:
          JSON.parse(json)
      }

      log(JSON.stringify(params))

      const postResponse = await doPost1Try(`https://aykgxkcf05.execute-api.ap-southeast-2.amazonaws.com/prod/ton-change-request`, params);
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_400) {
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setMessageId(postResponse.data.messageId);
        setIsFailedToRetrieveData(false);
        handleClear()
        setShowSuccessAlert(true)
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setMessageId("");
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setMessageId("");
        setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log(`error while sending xml file`);
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };
  const handleXmlSubmit = async (event) => {

    event.preventDefault();

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);
      setShowSuccessAlert(false)

      log(`sending xml to api`);

      let params = {
        xmlMessage: xml,
        country: TENANT_ID_COUNTRY_MAP.get(TENANT_D101),
        tenantId: TENANT_D101,
      };

      log(params);
      // const postResponse = await doPost1Try(`https://aykgxkcf05.execute-api.ap-southeast-2.amazonaws.com/prod/portal/inbound-xml`, params);
      const postResponse = await doPost1Try(`${process.env.REACT_APP_BASE_API_ENDPOINT}/process/inbound-xml`, params);
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_400) {
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setMessageId(postResponse.data.messageId);
        setIsFailedToRetrieveData(false);
        handleClear()
        setShowSuccessAlert(true)
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setMessageId("");
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setMessageId("");
        setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log(`error while sending xml file`);
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };
  const handleTextBoxChange = (e) => {

    if (documentType === "xml") {
      log("checking xml")

      if (e.target.value.length > 0) {
        try {

          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(e.target.value, 'text/xml');
          console.log(xmlDoc);

          // If parsing succeeds, consider it valid XML
          setIsInvalidXml(xmlDoc.getElementsByTagName('parsererror').length >= 1);

        } catch (error) {
          // If parsing fails, consider it invalid XML
          setIsInvalidXml(true);

        }
      }
      log(`isInvalidXml: ${isInvalidXml}`)
      setXml(e.target.value)
    } else if (documentType === "json") {
      if (e.target.value.length > 0) {
        try {
          const parsedJson = JSON.parse(e.target.value)
          setIsInvalidJson(false)

        } catch (error) {
          // If parsing fails, consider it invalid JSON
          setIsInvalidJson(true);

        }
      }
      log(`isInvalidJson: ${isInvalidJson}`)
      setJson(e.target.value)
    }


  }
  const handleClear = () => {
    setXml("")
    setJson("")
  }


  return (
    <>
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col className="search-results-title" id={determineTenantNamePassId(TENANT_D101)}>
            <strong>{title}</strong>
          </Col>
        </Row>
        <Row >

          {
            isLoading ? <Col className="spinner-col" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
              <Spinner animation="border" variant="danger" style={{ width: "100px", height: "100px" }} />
            </Col> : <>

              <Col>

                <Nav
                  variant="tabs"
                  defaultActiveKey={activeNavTab}
                  style={{ marginTop: "10px" }}
                >
                  <Nav.Item
                    className={documentType === "xml" ? "active-document-tab" : null}
                  >
                    <Nav.Link
                      eventKey={1}
                    >
                      <strong>POST /XML</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className={documentType === "json" ? "active-document-tab" : null}
                  >
                    <Nav.Link
                      eventKey={2}
                    >
                      <strong>POST /JSON</strong>
                    </Nav.Link>

                  </Nav.Item>
                </Nav>

                {documentType === "xml" && <Form.Control value={xml} placeholder='Paste xml here' onChange={handleTextBoxChange} as="textarea" aria-label="With textarea" style={{ width: "100%", marginBottom: "10px", minHeight: "400px" }} />}
                {documentType === "json" && <Form.Control value={json} placeholder='Paste JSON here' onChange={handleTextBoxChange} as="textarea" aria-label="With textarea" style={{ width: "100%", marginBottom: "10px", minHeight: "400px" }} />}

              </Col>
              {/*  */}
              <Row>
                <Col>
                  <div>

                  </div>
                  {documentType === "xml" && <Button type="submit" className="mt-2" variant={isInvalidXml ? "danger" : "success"}>
                    {isInvalidXml ? "XML is invalid" : "XML is valid"}
                  </Button>}
                  {documentType === "json" && <Button type="submit" className="mt-2" variant={isInvalidJson ? "danger" : "success"}>
                    {isInvalidJson ? "JSON is invalid" : "JSON is valid"}
                  </Button>}
                </Col>
                <Col><div style={{ marginBottom: "10px" }}>
                  {/* <SearchButton onClick={handleXmlSubmit} isDisabled={false}></SearchButton> */}

                  <Form.Group className="search-button-div">

                    {documentType === "xml" && <Button type="submit" className="mt-2 search-btn" onClick={handleXmlSubmit} disabled={xml === "" || isInvalidXml}>
                      Submit
                    </Button>}
                    {documentType === "json" && <Button type="submit" className="mt-2 search-btn" onClick={handleJsonSubmit} disabled={json === "" || isInvalidJson}>
                      Submit
                    </Button>}

                    {" "}
                    <Button type="submit" className="mt-2 search-btn" onClick={handleClear}>
                      Clear
                    </Button>
                  </Form.Group>
                </div></Col>
              </Row>
            </>
          }
        </Row>
      </Container >
      {showSuccessAlert && (
        <div className="d-flex justify-content-end">
          <Alert className="move-in-alert">
            Message ID:{" "}
            <strong>


              <span style={{
                cursor: "pointer",
                // textDecoration: "underline",
                color: "white",
              }}>  {messageId}</span>


            </strong>{" "}
            successfully submitted{" "}
            <Button
              className="ml-auto"
              onClick={(e) => {
                setShowSuccessAlert(false);
              }}
            >
              X
            </Button>
          </Alert>
        </div>
      )}
    </>
  )
}

export default POCTextBox