import React, { useState } from "react";
import SearchViewWrapper from "../wrappers/SearchViewWrapper";
import SearchInputWrapper from "../wrappers/SearchInputWrapper";
import SearchResultWrapper from "../wrappers/SearchResultWrapper";
import D101ProcessRequestIdSearch from "../inputs/D101ProcessRequestIdSearch";
import { determineTenantName } from "../../../utils/ALL/tenantUtils";
import BootstrapTable from "react-bootstrap-table-next";
import D101ProcessAgentIdSearch from "../inputs/D101ProcessAgentIdSearch";
import moment from "moment-timezone";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  DEFAULT_SORTED_2,
  PAGINATION_OPTIONS_1,
} from "../../../utils/bootStrapTableUtils";
import { rowStyler } from "../../SearchResults/NMISearchResults/utils/NMISearchResultUtils";

const D101ProcessSearch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchType, setSearchType] = useState("");

  const [searchResult, setSearchResult] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const columns = [
    {
      dataField: "requestid",
      text: "Process Request ID",
      formatter: (cell, row) => {
        return (
          <a
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
            onClick={() => {
              window.open(
                // `/process-request-details?requestId=${cell}&nmi=${
                //   row.nmi
                // }&requestType=${row.nmitype.split("#")[1]}`
                `process-request-details?requestId=${cell}`
              );
            }}
          >
            {cell}
          </a>
        );
      },
    },
    {
      dataField: "nmi",
      text: "NMI",
    },
    {
      dataField: "datecreated",
      text: "Created Date",
      formatter: (cell) => {
        const timezoneOffset = moment.parseZone(cell).utcOffset();

        // Parse and format the date with the original timezone
        return moment
          .tz(cell, "UTC")
          .utcOffset(timezoneOffset)
          .format("YYYY/MM/DD HH:mm");
      },
    },
    {
      dataField: "agent",
      text: "Agent ID",
    },
    {
      dataField: "requestType",
      text: "Transaction Type",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "source",
      text: "Source",
    },
  ];

  const basePros = {
    setIsLoading: setIsLoading,
    setIsFailedToRetrieveData: setIsFailedToRetrieveData,
    setErrorMessage: setErrorMessage,
    setSearchType: setSearchType,
  };
  return (
    <SearchViewWrapper>
      <SearchInputWrapper
        tenantId={"D101"}
        searchInputTitle={"Search"}
        defaultActiveKey={0}
      >
        <D101ProcessRequestIdSearch
          eventKey={0}
          setProcessRequestIdSearch={setSearchResult}
          {...basePros}
        ></D101ProcessRequestIdSearch>
        <D101ProcessAgentIdSearch
          eventKey={1}
          setProcessAgentIdSearch={setSearchResult}
          {...basePros}
        ></D101ProcessAgentIdSearch>
      </SearchInputWrapper>
      <SearchResultWrapper
        tenantId={"D101"}
        searchResultTitle={"Search Results"}
        isLoading={isLoading}
      >
        {searchResult.length > 0 ? (
          <BootstrapTable
            columns={columns}
            keyField="nmitype"
            data={searchResult}
            pagination={paginationFactory(PAGINATION_OPTIONS_1)}
            bootstrap4
            rowStyle={rowStyler}
            classes="search-result-table"
            // defaultSorted={[
            //   {
            //     dataField: "datecreated",
            //     order: "asc",
            //   },
            // ]}
            wrapperClasses="search-result-wrapper"
            headerWrapperClasses={determineTenantName()}
            data-test="search-result-table"
          ></BootstrapTable>
        ) : (
          <div>No result found</div>
        )}
        {errorMessage && <div>{errorMessage}</div>}
      </SearchResultWrapper>
    </SearchViewWrapper>
  );
};

export default D101ProcessSearch;
