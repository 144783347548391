import React from "react";
import { Button, Form } from "react-bootstrap";
import "./SearchButton.css";
import { log } from "../../../../utils/ALL/loggingUtils";
const SearchButton = ({ onClick, isDisabled }) => {
  return (
    <Form.Group className="search-button-div">
      <Button type="submit" className="mt-2 search-btn" onClick={onClick} disabled={isDisabled}>
        Search
      </Button>
    </Form.Group>
  );
};

export default SearchButton;
