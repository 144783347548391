import React, { useState } from "react";
import {
  Alert,
  Button,
  CloseButton,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  TENANT_D101,
  determineTenantNamePassId,
} from "../../../utils/ALL/tenantUtils";
import DatePicker from "../../Common/ReusableComponents/DatePicker/DatePicker";
import { DATE_PICKER_FORMAT_1 } from "../../Common/ReusableComponents/DatePicker/DatePickerUtils";
import moment from "moment-timezone";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
  doPost1Try,
} from "../../../utils/apiUtils";
import "./MoveIn.css";
import { log } from "../../../utils/ALL/loggingUtils";
const MoveIn = () => {
  const [nmi, setNmi] = useState("");
  const [proposedDate, setProposedDate] = useState(new Date());
  const [participantID, setParticipantID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showUnsuccessfulAlert, setShowUnsuccessfulAlert] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [requestId, setRequestId] = useState("");
  // const [date, setDate] = useState(new Date());
  const [isNmiValid, setIsNmiValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const onDateSelect = (date) => {
    setProposedDate(date);
  };
  const handleMoveIn = async (e) => {
    e.preventDefault();

    try {
      setShowSuccessAlert(false);
      setIsLoading(true);

      const params = {
        body: {
          participantId: participantID,
          mirn: nmi,
          moveIn: "Yes",
          actualEndDate: "",
          proposedDate: moment(proposedDate).format("yyyy-MM-DD"),
          transactionType: "CATSChangeRequest",
          requestType: "MoveIn",
        },
      };
      console.log(params);

      setIsLoading(true);

      const postResponse = await doPost1Try(
        `https://aykgxkcf05.execute-api.ap-southeast-2.amazonaws.com/prod/ton-change-request`,
        params
      );

      console.log(postResponse);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("No XML returned");

        setShowUnsuccessfulAlert(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setTransactionId(postResponse.data.transactionID);
        setRequestId(postResponse.data.requestID);
        setShowSuccessAlert(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        log("API responded with 504 code");
        setErrorMessage("Taking too long to process the request");
      } else {
        log("Unhandled http status code");
        setErrorMessage("Unhandled Error");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUnsuccessfulAlert(false);
    }
  };

  return (
    // TODO: consider creating a common components
    <div
      // className="background-image-wrapper"
      id={determineTenantNamePassId(TENANT_D101)}
    >
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col
            className="search-results-title"
            id={determineTenantNamePassId(TENANT_D101)}
          >
            Electricity - Move-In
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="wrapper-div">
            <div
              className={`${determineTenantNamePassId(
                TENANT_D101
              )}-nmi-discovery-type-3-search-title`}
            >
              Trigger Move-In Process
            </div>
            <div className="search-result-div">
              <Form>
                <Form.Group>
                  <Form.Label>NMI</Form.Label>
                  <Form.Control
                    name="nmi"
                    type="number"
                    onChange={(e) => setNmi(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Proposed Date</Form.Label>
                  <DatePicker
                    className="historical-date-pick"
                    // selected={date}
                    selected={proposedDate}
                    onChange={onDateSelect}
                    dateFormat={DATE_PICKER_FORMAT_1}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                  ></DatePicker>
                </Form.Group>
                <Form.Group onChange={(e) => setParticipantID(e.target.value)}>
                  <Form.Label>Participant ID</Form.Label>
                  <Form.Select>
                    <option value="">Select Participant</option>
                    <option value={"HANWHA"}>HANWHA</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="search-button-div">
                  <Button
                    type="submit"
                    className="mt-2 search-btn"
                    onClick={handleMoveIn}
                    disabled={!participantID && !nmi && !proposedDate}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Row>
        {isLoading && (
          <div className="spinner-div-searching">
            <Spinner
              animation="border"
              variant="danger"
              className="spinner-searching"
            />
          </div>
        )}
      </Container>
      {showSuccessAlert && (
        <div className="d-flex justify-content-end">
          <Alert className="move-in-alert">
            Request Id:{" "}
            <strong>
              {" "}
              <a
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "white",
                }}
                onClick={() => {
                  window.open(`process-request-details?requestId=${requestId}`);
                }}
              >
                {requestId}
              </a>
            </strong>{" "}
            successfully submitted{" "}
            <Button
              className="ml-auto"
              onClick={(e) => {
                setShowSuccessAlert(false);
              }}
            >
              X
            </Button>
          </Alert>
        </div>
      )}
    </div>
    // <>
    //   <div class="container mt-4 mb-4">
    //     {/* Outer Card */}
    //     <div class="card">
    //       <div class="card-header">Move in</div>
    //       <div class="card-body">
    //         <div class="row">
    //           <div class="col">
    //             {/* Inner Card */}
    //             <div class="card">
    //               <div class="card-header">Trigger Move-In Process</div>
    //               <div class="card-body">
    //                 <Form>
    //                   <Form.Group>
    //                     <Form.Label>NMI</Form.Label>
    //                     <Form.Control
    //                       name="nmi"
    //                       type="text"
    //                       onChange={(e) => setNmi(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>

    //                   <Form.Group>
    //                     <Form.Label>Proposed Date</Form.Label>
    //                     <Form.Control
    //                       name="nmi"
    //                       type="text"
    //                       onChange={(e) => setProposedDate(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   <Form.Group>
    //                     <Form.Label>Participant ID</Form.Label>
    //                     <Form.Control
    //                       name="nmi"
    //                       type="text"
    //                       onChange={(e) => setParticipantID(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>

    //                   <Form.Group className="search-button-div">
    //                     <Form.Group className="search-button-div">
    //                       <Button
    //                         type="submit"
    //                         className="mt-2 search-btn"
    //                         onClick={handleMoveIn}
    //                         disabled={false}
    //                       >
    //                         Submit
    //                       </Button>
    //                     </Form.Group>
    //                     {/* <SearchButton
    //                     onClick={handleMoveIn}
    //                     isDisabled={false}
    //                   ></SearchButton> */}
    //                   </Form.Group>
    //                 </Form>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {isLoading && (
    //     <div className="spinner-div-searching">
    //       <Spinner
    //         animation="border"
    //         variant="danger"
    //         className="spinner-searching"
    //       />
    //     </div>
    //   )}
    // </>
  );
};

export default MoveIn;
