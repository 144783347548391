import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import OriginReportsNavBar from "./1001/OriginReportsNavBar";
import { TENANT_1001 } from "../../../../utils/ALL/tenantUtils";
import { log } from "../../../../utils/ALL/loggingUtils";

//add validation if user can see reports tab
const AUCommonReportsNavBar = ({ accessGroups }) => {
  const tenantId = getUserTenantId();

  const location = useLocation();
  const currentPath = location.pathname;
  const [className, setClassName] = useState("");

  useEffect(() => {
    log("change on the path");
    log("currentPath: " + currentPath);
    if (
      [
        "/gateway-throughput",
        "/so-status",
        "/stop-alerts",
        "/s2s-recon",
      ].includes(currentPath)
    ) {
      setClassName("active-drop-down-nav");
    } else {
      setClassName("");
    }
  }, [currentPath]);
  if (tenantId === TENANT_1001) {
    // return null;
    // uncomment when ready to be use
    return (
      <OriginReportsNavBar accessGroups={accessGroups}></OriginReportsNavBar>
    );
  } else if (tenantId === "1005") {
    // return null;
    // TODO: uncomment when ready to be used (Updated Code)
    return (
      <NavDropdown
        title="Reports"
        id="header-nav-dropdown-admin"
      >
        <NavDropdown.Item
          as={Link}
          to="/gateway-throughput"
        >
          Gateway Throughput
        </NavDropdown.Item>
      </NavDropdown>
    );
  } else if (tenantId === "D101") {
    return (
      <NavDropdown
        title="Report"
        id="header-nav-dropdown-admin"
        className={className}
      >
        <NavDropdown.Item
          as={Link}
          to="/gateway-throughput"
        >
          Gateway Throughput
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/so-status"
        >
          Service Order Status
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/stop-alerts"
        >
          Stop Alert Status
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/s2s-recon"
        >
          System-to-System Reconciliation
        </NavDropdown.Item>
      </NavDropdown>
    );
  } else {
    return null;
  }
};
//
export default AUCommonReportsNavBar;
