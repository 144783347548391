import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { log } from "../../../utils/ALL/loggingUtils";
// import { TENANT_ID_COUNTRY_MAP } from "../../../utils/ALL/tenantUtils";
import { FUEL_TYPE_ELEC } from "../../../utils/AU/constantAU";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_401,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_TRANSACTION_ID,
} from "../AU/AUSearchViewUtils";
import SearchInputAccordionItem from "./common-components/SearchInputAccordionItem";
import {
  TENANT_1001,
  TENANT_D101,
  TENANT_d101,
  TENANT_ID_COUNTRY_MAP,
} from "../../../utils/ALL/tenantUtils";

const TransactionIdSearch = ({
  eventKey,
  setSearchDataType,
  setTxnIdSearchResult,
  setSearchResultFuelType,
  fuelType = FUEL_TYPE_ELEC.toLowerCase(),
  setSearchType,
  setIsLoading,
  setIsFailedToRetrieveData,
  setErrorMessage,
  tenantId,
  setHideUnsolicitedColumn,
  setHideSONumberColumn,
}) => {
  const [transactionId, setTransactionId] = useState("");
  const handleTxnIdSearch = async (event) => {
    event.preventDefault();
    setHideUnsolicitedColumn(true);
    setHideSONumberColumn(true);
    setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
    setSearchResultFuelType(fuelType);
    setTxnIdSearchResult([]);

    setSearchType(SEARCH_TYPE_TRANSACTION_ID);
    log(`Searching for TransactionID: ${transactionId}`);

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      let params = {
        transactionId: transactionId,
        country: TENANT_ID_COUNTRY_MAP.get(tenantId),
        tenantId,
      };

      if (tenantId === TENANT_1001 || tenantId === TENANT_D101) {
        params.fuelType = fuelType;
      }

      log(params);
      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/transactionid`,
        params
      );

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log(postResponse.data);
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_401) {
        alert("You are no longer authorized. Please LogIn again");
        return await Auth.signOut();
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setTxnIdSearchResult(postResponse.data);
        postResponse.data.map((masTxnId) => {
          log("checking the unsolicited");
          if (masTxnId.unsolicited === "true") {
            log("unsolicited existing");
            setHideUnsolicitedColumn(false);
          }
          if (masTxnId.masterTxnType === "Service Order") {
            log("Service Order Type");
            setHideSONumberColumn(false);
          }
        });
        setIsFailedToRetrieveData(false);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setTxnIdSearchResult([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setTxnIdSearchResult([]);
        setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log("error in searching transaction id");
      log(error);
      setIsLoading(false);
    }
  };

  const txnIdSearchProps = {
    eventKey,
    accordionHeaderTitle: "Transaction ID",
    formLabel: "Transaction ID",
    formControlName: "transactionId",
    onChange: setTransactionId,
    onButtonClick: handleTxnIdSearch,
    isSearchButtonDisabled: false,
  };
  return (
    <SearchInputAccordionItem {...txnIdSearchProps}></SearchInputAccordionItem>
  );
};

export default TransactionIdSearch;
