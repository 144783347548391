import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { getUserTenantId } from "../../../../../utils/ALL/localStorageUtils";
import { auAbbreviations } from "../../../../../utils/AU/constantAU";
import {
  TENANT_1001,
  TENANT_1002,
  TENANT_D101,
  TENANT_d101,
} from "../../../../../utils/ALL/tenantUtils";
import {
  sortByDateCreatedOrigin,
  sortByDateNoFormatAscending,
  sortByDateNoFormatDescending,
} from "../../../../../utils/ALL/arraySort";
import {
  DATE_FORMAT_3,
  convertUtcDateTimeToAedtAndFormat,
  convertUtcDateTimeToAestAndFormat,
  determineIfAestOrAedt,
  formatDateTimeTypeA,
  isAuSydneyInDST,
} from "../../../../../utils/dateTimeUtils";

const AUElecMasterTransaction = ({
  transactions,
  nmi,
  masterTransactionTxnType,
  masterTxnId,
  catsChangeReasonCode,
  sordNumber,
  requestId,
}) => {
  const TENANT_ID = getUserTenantId();

  let sortedTransaction = [];
  if (TENANT_ID === TENANT_1001) {
    sortedTransaction = transactions.sort(sortByDateCreatedOrigin);
  } else if (TENANT_ID === TENANT_D101 || TENANT_ID === TENANT_d101) {
    sortedTransaction = transactions.sort(sortByDateNoFormatAscending);
    console.log(sortedTransaction);
  } else {
    sortedTransaction = transactions;
  }

  const firstTxn = sortedTransaction[0];

  const formatDateCreated = (dateTime) => {
    if (TENANT_ID === TENANT_1001) {
      return `${formatDateTimeTypeA(
        dateTime,
        DATE_FORMAT_3
      )} (${determineIfAestOrAedt()})`;
    } else if (
      TENANT_ID === TENANT_1002 ||
      TENANT_ID === TENANT_D101 ||
      TENANT_ID === TENANT_d101
    ) {
      if (isAuSydneyInDST()) {
        return `${convertUtcDateTimeToAedtAndFormat(
          dateTime,
          DATE_FORMAT_3
        )} (${determineIfAestOrAedt()})`;
      } else {
        return `${convertUtcDateTimeToAestAndFormat(
          dateTime,
          DATE_FORMAT_3
        )} (${determineIfAestOrAedt()})`;
      }
    } else {
      return dateTime;
    }
  };

  const sourceDestinationFormatter = (valueToCheck) => {
    let value = valueToCheck;
    auAbbreviations.map((data) => {
      if (data.abbreviation === value.toUpperCase()) {
        value = data.meaning;
      }
    });
    return value;
  };

  return (
    <Row
      className="master-transaction-row master-txn-wrapper-child"
      data-test="master-transaction-component"
    >
      <Col>
        <Table
          size="sm"
          borderless
        >
          <thead>
            <tr>
              <td>
                <strong>Master Transaction</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Date Created:</td>
              <td>{formatDateCreated(firstTxn.dateCreated)}</td>
              {/* <td></td>
              <td></td> */}
              {/* <td>Role</td>
              <td>FRMP</td> */}
              <td>Source:</td>
              <td>{sourceDestinationFormatter(firstTxn.sender)}</td>
            </tr>
            <tr>
              <td>Master Transaction ID:</td>
              <td>{masterTxnId}</td>
              <td>Destination</td>
              <td>{sourceDestinationFormatter(firstTxn.receiver)}</td>
            </tr>
            <tr>
              <td>Customer Reference Number:</td>
              <td>{nmi}</td>
              <td>Service Order ID:</td>
              <td>{sordNumber}</td>
            </tr>
            <tr>
              <td>Transaction Type</td>
              <td>{firstTxn.transactionType}</td>
              <td>Request ID:</td>
              <td>{requestId}</td>
            </tr>
            <tr>
              <td>Change Reason Code</td>
              <td>{catsChangeReasonCode}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default AUElecMasterTransaction;
