import React from "react";
import AUTxnFlowDiagram from "../TxnFlowDiagram/AUTxnFlowDiagram";
import AUElecMasterTransaction from "../MasterTransaction/AU/Elec/AUElecMasterTransaction";
import AURejectionDetails from "../RejectionDetails/AU/AURejectionDetails";
import AuObjectionDetails from "../ObjectionDetails/AU/AUObjectionDetails";
import AUAssociatedTransactionsCommon from "../AssociatedTransactions/AU/AUAssociatedTransactionsCommon";

const D101MasterTxnDetailsView = ({ associatedTransactions, urlParams }) => {
  let catsChangeReasonCode =
    urlParams.crcode !== "null" ? urlParams.crcode : "";
  let sordNumber = "";
  let catsRequestId = "";

  associatedTransactions.map((data) => {
    // if (data.changeReasonCode !== null) {
    //   catsChangeReasonCode = data.changeReasonCode;
    // }

    // if (data.soNumber !== null || data.soNumber !== "") {
    //   sordNumber = data.soNumber;
    // }

    if (data.serviceOrderNumber !== null) {
      sordNumber = data.serviceOrderNumber;
    }

    if (data.requestId !== null) {
      catsRequestId = data.requestId;
    }
  });
  return (
    <>
      <AUTxnFlowDiagram
        fuelType={urlParams.fuel}
        txnStatus={urlParams.status}
        txnGroup={associatedTransactions[0].txnGroup.toUpperCase()}
      ></AUTxnFlowDiagram>
      <AUElecMasterTransaction
        transactions={associatedTransactions}
        nmi={urlParams.nmi}
        masterTransactionTxnType={urlParams.txnType}
        masterTxnId={urlParams.masterTxnId}
        catsChangeReasonCode={catsChangeReasonCode}
        sordNumber={sordNumber}
        requestId={catsRequestId}
      ></AUElecMasterTransaction>
      <AURejectionDetails></AURejectionDetails>
      <AuObjectionDetails></AuObjectionDetails>
      <AUAssociatedTransactionsCommon
        associatedTransactions={associatedTransactions}
      ></AUAssociatedTransactionsCommon>
    </>
  );
};

export default D101MasterTxnDetailsView;
