import React from "react";
import SearchButton from "../../../Common/ReusableComponents/SearchButton/SearchButton";
import { Accordion, Form } from "react-bootstrap";

const SearchInputAccordionItem = ({
  eventKey,
  accordionHeaderTitle,
  formLabel,
  formControlName,
  onChange,
  onButtonClick,
  isSearchButtonDisabled,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header className="items-search-header">{accordionHeaderTitle}</Accordion.Header>
      <Accordion.Body>
        <Form>
          <Form.Group>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control name={formControlName} type="text" onChange={(e) => onChange(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="search-button-div">
            <SearchButton onClick={onButtonClick} isDisabled={isSearchButtonDisabled}></SearchButton>
          </Form.Group>
        </Form>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SearchInputAccordionItem;
