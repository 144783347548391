import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const Meters = ({ data }) => {
  // const data = {
  //   serialNumber: 814788,
  //   status: "C",
  //   installationTypeCode: "BASIC",
  //   nextScheduledReadDate: "2018-01",
  //   rowStatus: "C",
  // };
  //
  const firstColumnMap = new Map([
    ["Meter", data.meter],
    ["Status: ", data.status],
    ["Sequence number:", data.sequenceNumber],
    ["Maintained At:", data.maintainedAt],
    ["Created At:", data.createdAt],
    ["Serial Number:", data.serialNumber],
    ["Status:", data.status],
    ["Installation Type Code:", data.installationTypeCode],
    ["Next Scheduled Read Date:", data.nextScheduledReadDate],
    ["Location:", data.location],
    ["Hazard:", data.hazard],
    ["Manufacturer:", data.manufacturer],
    ["Model:", data.model],
    ["Transformer Location:", data.transformerLocation],
    ["Transformer Ratio:", data.transformerRatio],
    ["Transformer Type:", data.transformerType],
    ["Measurement Type:", data.measurementType],
    ["Constant:", data.constant],
    ["Point:", data.point],
    ["Program:", data.program],
    ["Read Type Code:", data.readTypeCode],
    ["Current Transformer Location:", data.currentTransformerLocation],
    ["Current Transformer Type:", data.currentTransformerType],
    [
      "Current Transformer Ratio Available:",
      data.currentTransformerRationAvailable,
    ],
    [
      "Current Transformer Ratio Connected:",
      data.currentTransformerRationConnected,
    ],
    [
      "Current Transformer Accuracy Class:",
      data.currentTransformerAccuracyClass,
    ],
    ["Current Transformer Test:", data.currentTransformerTest],
    ["Current Transformer Test Date:", data.currentTransformerTestDate],
  ]);

  const secondColumnMap = new Map([
    ["Row Status:", data.rowStatus],
    ["Last Seen:", data.lastSeen],
    ["Inactivated for FRMPs:", data.inactivatedForFrmps],
    ["Route:", data.route],
    ["Additional Site Information:", data.additionalSiteInformation],
    ["Asset Management Plan:", data.assetManagementPlan],
    ["Calibration Tables:", data.calibrationTables],
    ["Communications Equipment Type:", data.communicationEquipmentType],
    ["Communications Protocol:", data.communicationsProtocol],
    ["Data Conversion:", data.dataConversion],
    ["Data Validations:", data.dataValidations],
    ["Estimation Instructions:", data.estimationInstructions],
    ["GPS Coordinates Latitude:", data.gpsCoordinatesLatitude],
    ["GPS Coordinates Longitude:", data.gpsCoordinatesLongitude],
    ["Use:", data.use],
    ["Remote Phone Number:", data.remotePhoneNumber],
    ["Last Test Date:", data.lastTestDate],
    ["Test Calibration Program:", data.testCalibrationProgram],
    ["Test Performed By:", data.testPerformedBy],
    ["Test Result Accuracy:", data.testResultAccuracy],
    ["Test Result Notes:", data.testResultNotes],
    ["Test Result:", data.testResult],
    ["User Access Rights:", data.userAccessRights],
    ["Voltage Transformer Location:", data.voltageTransformerLocation],
    ["Voltage Transformer Type:", data.voltageTransformerType],
    ["Voltage Transformer Ratio:", data.voltageTransformerRatio],
    [
      "Voltage Transformer Accuracy Class:",
      data.voltageTransformerAccuracyClass,
    ],
    ["Voltage Transformer Test:", data.voltageTransformerTest],
    ["Voltage Transformer Test Date:", data.voltageTransformerTestDate],
  ]);
  return (
    <Row
    // style={{
    //   marginBottom: "60px",
    // }}
    >
      <Col>
        <Table>
          {Array.from(firstColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
      <Col>
        <Table>
          {Array.from(secondColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
    </Row>
  );
};

export default Meters;
