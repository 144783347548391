import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { log } from "../../../utils/ALL/loggingUtils";

const ProcessDropdownNavbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [className, setClassName] = useState("");

  useEffect(() => {
    log("change on the path");
    log("currentPath: " + currentPath);
    if (["/transaction-upload"].includes(currentPath)) {
      setClassName("active-drop-down-nav");
    } else {
      setClassName("");
    }
  }, [currentPath]);
  return (
    <NavDropdown
      title="Process"
      id="header-nav-dropdown-admin"
      className={className}
    >
      <NavDropdown.Item
        as={Link}
        to={"/transaction-upload"}
        //className="nav-dropdown-item"
        className="nav-dropdown-item nav-link"
      >
        {"Transaction Upload"}
      </NavDropdown.Item>
      {/* Electricity Dropdown */}
      {/* <NavDropdown
        title="Retailer"
        id="nested-dropdown"
        drop="end"
      >
        <NavDropdown
          title="Electricity"
          id="nested-dropdown"
          drop="end"
        >
          <NavDropdown.Item
            as={Link}
            to="/nmi-type-1"
          >
            NMI Discovery
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to="/nmi-type-3"
          >
            NMI Standing Data
          </NavDropdown.Item>
        
          <NavDropdown
            title="Move-In/Transfer-In"
            id="nested-dropdown"
            drop="end"
          >
            <NavDropdown.Item
              as={Link}
              to="/move-in"
            >
              Move-In
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/transfer-in"
            >
              Transfer-In
            </NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
      </NavDropdown> */}
      {/* <NavDropdown title="Meter Provider" id="nested-dropdown" drop="end">
        <NavDropdown title="Post XML" id="nested-dropdown" drop="end">
          <NavDropdown.Item as={Link} to="/post-transaction?txnType=so-request&document=xml">
            Service Order Request
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Post JSON" id="nested-dropdown" drop="end">
          <NavDropdown.Item as={Link} to="/post-transaction?txnType=so-response&document=json">
            Service Order Response
          </NavDropdown.Item>
        </NavDropdown>
      </NavDropdown> */}

      {/* Gas Dropdown */}
      {/* <NavDropdown title="Gas" id="nested-dropdown" drop="end">
        <NavDropdown.Item as={Link} to="/#">
          Add Items Here
        </NavDropdown.Item>
      </NavDropdown> */}
    </NavDropdown>
  );
};

export default ProcessDropdownNavbar;
