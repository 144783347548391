import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const NMIDataStreams = ({ data }) => {
  // const data = {
  //   suffix: 11,
  //   status: "I", //status inside <DataStream> or other?
  //   rowStatus: "I",
  //   dataStreamType: "Consumption",
  //   averageDailyLoad: 17,
  //   profileName: "NSLP",
  // };
  const firstColumnMap = new Map([
    // ["Sequence number:", data.serialNumber],
    // ["Maintained At:", data.maintainedAt],
    // ["Created At:", data.createdAt],
    ["Suffix:", data.suffix],
    ["Status:", data.status],
  ]);
  const secondColumnMap = new Map([
    ["Row Status:", data.status],
    // ["Last Seen:", data.lastSeen],
    // ["Inactivated for FRMPs:", data.inactivatedForFrmps],
    ["Data Stream Type:", data.datastreamtype],
    ["Average Daily Load:", data.averageddailyload],
    ["Profile Name:", data.profilename],
  ]);
  return (
    <Row>
      <Col>
        <Table>
          {Array.from(firstColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
      <Col>
        <Table>
          {Array.from(secondColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
    </Row>
  );
};

export default NMIDataStreams;
