export const SEARCH_TYPE_NMI = "nmi";
export const SEARCH_TYPE_TRANSACTION_ID = "transaction-id";
export const SEARCH_TYPE_HISTORICAL_NMI = "historical-nmi";
export const SEARCH_TYPE_FILENAME = "filename";
export const SEARCH_TYPE_MESSAGE_ID = "message-id";
export const SEARCH_TYPE_CATS_REQUEST_ID = "cats-request-id";
export const SEARCH_TYPE_SORD_NUMBER = "so-number";

export const SEARCH_DATA_TYPE_ACTIVE = "active";
export const SEARCH_DATA_TYPE_HISTORICAL = "historical";
