import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <Row className="spinner-div master-txn-view-spinner-wrapper">
      <Col className="spinner-col">
        <Spinner animation="border" variant="danger" className="spinner-master-txn-viewer" />
      </Col>
    </Row>
  );
};

export default LoadingSpinner;
