import {
  getUserCountry,
  getUserTenantId,
} from "../../utils/ALL/localStorageUtils";
import { TENANT_1001, TENANT_1005 } from "../../utils/ALL/tenantUtils";
import AUSearchView from "./AU/AUSearchView";
import NZSearchView from "./NZ/NZSearchView";
import "./Search.css";
import D101TransactionSearch from "./views/D101TransactionSearch";
import EqlTransactionSearchView from "./views/EqlTransactionSearchView";
import OriginTransactionSearchView from "./views/OriginTransactionSearchView";
const SearchViewHandler = ({ token, userId }) => {
  const userCountry = getUserCountry().toUpperCase();
  const tenantId = getUserTenantId();

  if (tenantId === TENANT_1005) {
    return <EqlTransactionSearchView token={token} userId={userId} />;
  } else if (tenantId === TENANT_1001) {
    return <OriginTransactionSearchView token={token} userId={userId} />;
  } else if (tenantId === "D101") {
    return <D101TransactionSearch></D101TransactionSearch>;
  } else if (userCountry === "AU") {
    // TODO: Nectr (1002) should also have there own component
    return <AUSearchView token={token} userId={userId}></AUSearchView>;
  } else if (userCountry === "NZ") {
    //TODO: OctoNZ (1003) should also have there own component, current this is being used
    return <NZSearchView></NZSearchView>;
  } else {
    return null;
  }
};

export default SearchViewHandler;
