import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { getUserTenantId } from "../../../utils/ALL/localStorageUtils";
import { log } from "../../../utils/ALL/loggingUtils";
import {
  PORTAL_LOG_ACTION_TYPE_NMI_SEARCH,
  logParams,
  logPortalActivity,
} from "../../../utils/ALL/portalLoggingUtils";
import {
  TENANT_1001,
  TENANT_D101,
  TENANT_ID_COUNTRY_MAP,
} from "../../../utils/ALL/tenantUtils";
import { FUEL_TYPE_ELEC, FUEL_TYPE_GAS } from "../../../utils/AU/constantAU";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_401,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import { tenantLocalTime } from "../../../utils/dateTimeUtils";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_NMI,
} from "../AU/AUSearchViewUtils";
import SearchInputAccordionItem from "./common-components/SearchInputAccordionItem";

const NMISearch = ({
  eventKey,
  fuelType = FUEL_TYPE_ELEC.toLowerCase(),
  setSearchDataType,
  setSearchResultFuelType,
  tenantId,
  token,
  userId,
  setSearchType,
  setNmiSearchResult,
  setIsLoading,
  setIsFailedToRetrieveData,
  setErrorMessage,
  setHideUnsolicitedColumn,
  setHideSONumberColumn,
}) => {
  const [nmi, setNmi] = useState("");
  const handleNmiSearch = async (event) => {
    event.preventDefault();
    setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
    setSearchResultFuelType(fuelType);
    setHideUnsolicitedColumn(true);
    setHideSONumberColumn(true);

    let nmiSearchParams = {
      meterPointId: nmi,
      country: TENANT_ID_COUNTRY_MAP.get(tenantId),
      tenantId: tenantId,
    };
    if (tenantId === TENANT_1001 || tenantId === TENANT_D101) {
      nmiSearchParams.fuelType = fuelType;
    }

    if (tenantId === TENANT_1001) {
      let newLogParams = logParams;
      newLogParams.userId = userId;
      newLogParams.dateInitiated = tenantLocalTime(getUserTenantId());
      newLogParams.action = PORTAL_LOG_ACTION_TYPE_NMI_SEARCH;
      newLogParams.token = token.toString();
      newLogParams.fuelType = fuelType;
      newLogParams.meterPointId = nmi;
      newLogParams.dataType = SEARCH_DATA_TYPE_ACTIVE;

      log(newLogParams);
      logPortalActivity(newLogParams);
    }

    setNmiSearchResult([]);
    setSearchType(SEARCH_TYPE_NMI);
    log(`Searching for NMI: ${nmi}`);

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      log(nmiSearchParams);

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/meterpointid`,
        nmiSearchParams
      );

      log(postResponse.data);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("Error while processing request");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_401) {
        alert("You are no longer authorized. Please LogIn again");
        return await Auth.signOut();
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        postResponse.data.map((masTxnId) => {
          log("checking the unsolicited");
          if (masTxnId.unsolicited === "true") {
            log("unsolicited existing");
            setHideUnsolicitedColumn(false);
          }
          if (masTxnId.masterTxnType === "Service Order") {
            log("Service Order Type");
            setHideSONumberColumn(false);
          }
        });

        setNmiSearchResult(postResponse.data);
        setIsFailedToRetrieveData(false);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setNmiSearchResult([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");

        setNmiSearchResult([]);
        setIsFailedToRetrieveData(true);
      }
      setIsLoading(false);
    } catch (error) {
      log("error in getting NMI details");
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };

  const nmiSearchProps = {
    eventKey,
    accordionHeaderTitle:
      fuelType === FUEL_TYPE_GAS.toLowerCase() ? "MIRN" : "NMI",
    formLabel: fuelType === FUEL_TYPE_GAS.toLowerCase() ? "MIRN" : "NMI",
    formControlName: "meterpointId",
    onChange: setNmi,
    onButtonClick: handleNmiSearch,
    isSearchButtonDisabled: false,
  };
  return (
    <SearchInputAccordionItem {...nmiSearchProps}></SearchInputAccordionItem>
  );
};

export default NMISearch;
