import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getUserCountry,
  getUserTenantId,
} from "../../../../utils/ALL/localStorageUtils";
import {
  TENANT_1001,
  TENANT_1002,
  TENANT_1005,
  TENANT_D101,
} from "../../../../utils/ALL/tenantUtils";
import { doPost } from "../../../../utils/apiUtils";
import { log, logStringifyJson } from "../../../../utils/ALL/loggingUtils";
import { SEARCH_DATA_TYPE_HISTORICAL } from "../../../Search/AU/AUSearchViewUtils";
import EqlMasterTxnDetailsView from "../../views/EqlMasterTxnDetailsView";
import NectrMasterTxnDetailsView from "../../views/NectrMasterTxnDetailsView";
import OriginMasterTxnDetailsView from "../../views/OriginMasterTxnDetailsView";
import MasterTxnDetailsViewWrapper from "../../wrappers/MasterTxnDetailsViewWrapper";
import D101MasterTxnDetailsView from "../../views/D101MasterTxnDetailsView";

const AUMasterTxnDetailViewContainer = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });

  const [associatedTransactions, setAssociatedTransactions] = useState([]);
  useEffect(() => {
    fetchAssociatedTransactions();
  }, []);
  const TENANT_ID = getUserTenantId();
  const fetchAssociatedTransactions = async () => {
    try {
      setIsFetchingData(true);
      setAssociatedTransactions([]);

      let params = {
        masterTxnId: urlParams.masterTxnId,
        country: getUserCountry(),
        tenantId: getUserTenantId(),
      };
      if (TENANT_ID === TENANT_1001 || TENANT_ID === TENANT_D101) {
        params.dbName = urlParams.dbName;
        params.fuelType = urlParams.fuel;
        params.txnGroup = urlParams.txnGroup;
      }

      //adding date initiated for 1001 historical search, in order for the query to run faster
      if (
        TENANT_ID === TENANT_1001 &&
        urlParams.dbName === SEARCH_DATA_TYPE_HISTORICAL
      ) {
        params.dateInitiated = urlParams.dateInitiated;
      }

      logStringifyJson(params);

      let endpoint = "";
      if (urlParams.dbName === "historical") {
        endpoint = "/view/historical/mastertxnid";
      } else {
        endpoint = "/view/mastertxnid";
      }

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}${endpoint}`,
        params
      );

      if (postResponse.status === 400) {
        setAssociatedTransactions([]);
        setIsApiError(true);
      } else if (postResponse.status === 401) {
        alert("You are no longer authorized. Please LogIn again");
      } else if (postResponse.status === 200) {
        setAssociatedTransactions(postResponse.data);
        setIsApiError(false);
      }
      setIsFetchingData(false);
      log("before postResponse.data");
      log(postResponse.data);
    } catch (err) {
      setAssociatedTransactions([]);
      setIsFetchingData(false);
      console.error(err);
    }
  };

  return (
    <MasterTxnDetailsViewWrapper
      tenantId={TENANT_ID}
      isFetchingData={isFetchingData}
      isApiError={isApiError}
      associatedTransactions={associatedTransactions}
    >
      {TENANT_ID === TENANT_1005 && (
        <EqlMasterTxnDetailsView
          associatedTransactions={associatedTransactions}
          urlParams={urlParams}
        ></EqlMasterTxnDetailsView>
      )}
      {TENANT_ID === TENANT_1001 && (
        <OriginMasterTxnDetailsView
          associatedTransactions={associatedTransactions}
          urlParams={urlParams}
        ></OriginMasterTxnDetailsView>
      )}
      {TENANT_ID === TENANT_1002 && (
        <NectrMasterTxnDetailsView
          associatedTransactions={associatedTransactions}
          urlParams={urlParams}
        ></NectrMasterTxnDetailsView>
      )}
      {TENANT_ID === "D101" && (
        <D101MasterTxnDetailsView
          associatedTransactions={associatedTransactions}
          urlParams={urlParams}
        ></D101MasterTxnDetailsView>
      )}
    </MasterTxnDetailsViewWrapper>
  );
};

export default AUMasterTxnDetailViewContainer;
