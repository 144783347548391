import React, { useState } from "react";
import { log } from "../../../utils/ALL/loggingUtils";
import { TENANT_ID_COUNTRY_MAP } from "../../../utils/ALL/tenantUtils";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_SORD_NUMBER,
} from "../AU/AUSearchViewUtils";
import SearchInputAccordionItem from "./common-components/SearchInputAccordionItem";

const SORDNumberSearch = ({
  eventKey,
  setSORDNumberSearchResult,
  setIsLoading,
  setIsFailedToRetrieveData,
  setSearchDataType,
  setSearchType,
  tenantId,
  setErrorMessage,
  setHideUnsolicitedColumn,
  setHideSONumberColumn,
}) => {
  const [sordNumber, setSordNumber] = useState("");
  const handleSORDNumberSearch = async (event, fuelType = "elec") => {
    event.preventDefault();

    try {
      setHideUnsolicitedColumn(true);
      setHideSONumberColumn(true);
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      setSORDNumberSearchResult([]);
      setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
      setSearchType(SEARCH_TYPE_SORD_NUMBER);
      log(`Searching for SORD Number: ${sordNumber}`);

      let params = {
        sordNumber: sordNumber,
        country: TENANT_ID_COUNTRY_MAP.get(tenantId),
        tenantId,
        fuelType,
      };

      log(params);
      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/sonumber`,
        params
      );
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_400) {
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        postResponse.data.map((masTxnId) => {
          log("checking the unsolicited");
          if (masTxnId.unsolicited === "true") {
            log("unsolicited existing");
            setHideUnsolicitedColumn(false);
          }
          if (masTxnId.masterTxnType === "Service Order") {
            log("Service Order Type");
            setHideSONumberColumn(false);
          }
        });
        setSORDNumberSearchResult(postResponse.data);
        setIsFailedToRetrieveData(false);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setSORDNumberSearchResult([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setSORDNumberSearchResult([]);
        setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log(`error in searching so number ${sordNumber}`);
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };
  const sordNumberSearchProps = {
    eventKey,
    accordionHeaderTitle: "SORD - SO Number",
    formLabel: "SORDNumber",
    formControlName: "sordNumber",
    onChange: setSordNumber,
    onButtonClick: handleSORDNumberSearch,
    isSearchButtonDisabled: false,
  };

  return (
    <SearchInputAccordionItem
      {...sordNumberSearchProps}
    ></SearchInputAccordionItem>
  );
};

export default SORDNumberSearch;
