import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import { TENANT_D101 } from "../../../../utils/ALL/tenantUtils";
import EQLSearchNav from "./1005/EQLSearchNav";
import AUCommonSearchNav from "./Common/AUCommonSearchNav";
import D101SearchNav from "./D101/D101SearchNav";

const AUSearchNavBarHandler = () => {
  const tenantId = getUserTenantId();
  if (tenantId === "1005") {
    return <EQLSearchNav></EQLSearchNav>;
  } else if (tenantId === TENANT_D101) {
    return <D101SearchNav></D101SearchNav>;
  } else {
    return <AUCommonSearchNav></AUCommonSearchNav>;
  }
  //   return <AUCommonSearchNav></AUCommonSearchNav>;
};

export default AUSearchNavBarHandler;
