import React from "react";
import { determineTenantNamePassId } from "../../../utils/ALL/tenantUtils";
import { Accordion, Col } from "react-bootstrap";

const SearchInputWrapper = ({ tenantId, searchInputTitle, defaultActiveKey, children }) => {
  return (
    <Col sm={3} className="search-input-column">
      <div className="search-input-title" id={determineTenantNamePassId(tenantId)}>
        {searchInputTitle}
      </div>
      <Accordion defaultActiveKey={defaultActiveKey}>{children}</Accordion>
    </Col>
  );
};

export default SearchInputWrapper;
