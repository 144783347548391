import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { FUEL_TYPE_ELEC } from "../../../../utils/AU/constantAU";
import {
  DEFAULT_SORTED_2,
  PAGINATION_OPTIONS_1,
} from "../../../../utils/bootStrapTableUtils";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_DATA_TYPE_HISTORICAL,
} from "../../../Search/AU/AUSearchViewUtils";
import {
  nmiSearchResultColumns,
  rowStyler,
} from "../utils/NMISearchResultUtils";
import "./NMISearchResultsWrapped.css";

const { ExportCSVButton } = CSVExport;
const NMISearchResultWrapped = ({
  apiResponseData,
  searchDataType,
  searchResultFuelType = FUEL_TYPE_ELEC.toLowerCase(),
  isFailedToRetrieveData,
  errorMessage,
  tenantId,
  hideUnsolicitedColumn = true,
  hideSONumberColumn = true,
}) => {
  const determineTableTitle = () => {
    let dataType = searchDataType.toLowerCase();
    if (dataType === SEARCH_DATA_TYPE_ACTIVE) {
      return "Active Search Results";
    } else if (dataType === SEARCH_DATA_TYPE_HISTORICAL) {
      return "Historical Search Results";
    }
  };

  if (apiResponseData.length > 0) {
    return (
      <div className="wrapper-div">
        <div className={`${determineTenantName()}-nmi-search-data-type-header`}>
          {determineTableTitle()}
        </div>
        <div className="search-result-div">
          {apiResponseData.length > 0 ? (
            <ToolkitProvider
              columns={nmiSearchResultColumns(
                tenantId,
                searchDataType,
                searchResultFuelType,
                hideUnsolicitedColumn,
                hideSONumberColumn
              )}
              data={apiResponseData}
              bootstrap4
              rowStyle={rowStyler}
              defaultSorted={DEFAULT_SORTED_2}
              data-test="search-result-table"
              exportCSV
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                    classes="search-result-table"
                    wrapperClasses="search-result-wrapper"
                    headerWrapperClasses={determineTenantName()}
                    pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                    keyField={"masterTxnId"}
                    defaultSorted={DEFAULT_SORTED_2}
                  />
                  {searchDataType.toLowerCase() === "historical" ? (
                    <div className="export-csv-button-div-wrapper">
                      <ExportCSVButton
                        {...props.csvProps}
                        className="csv-export-button"
                      >
                        Export to CSV
                      </ExportCSVButton>
                    </div>
                  ) : null}
                </div>
              )}
            </ToolkitProvider>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {isFailedToRetrieveData ? (
          <div>{errorMessage}</div>
        ) : (
          <div>No result found</div>
        )}{" "}
      </>
    );
  }
};

export default NMISearchResultWrapped;
