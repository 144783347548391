import React, { useState } from "react";
import { log } from "../../../utils/ALL/loggingUtils";
import { TENANT_ID_COUNTRY_MAP } from "../../../utils/ALL/tenantUtils";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_CATS_REQUEST_ID,
} from "../AU/AUSearchViewUtils";
import SearchInputAccordionItem from "./common-components/SearchInputAccordionItem";

const CATSRequestIdSearch = ({
  eventKey,
  setCATSRequestIdSearchResult,
  setIsLoading,
  setIsFailedToRetrieveData,
  setSearchDataType,
  setSearchType,
  tenantId,
  setErrorMessage,
  setHideUnsolicitedColumn,
  setHideSONumberColumn,
}) => {
  const [catsRequestId, setCatsRequestId] = useState("");
  const handleCATSRequestIdSearch = async (event, fuelType = "elec") => {
    event.preventDefault();

    try {
      setHideUnsolicitedColumn(true);
      setHideSONumberColumn(true);
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      setCATSRequestIdSearchResult([]);
      setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
      setSearchType(SEARCH_TYPE_CATS_REQUEST_ID);
      log(`Searching for CATS Request ID: ${catsRequestId}`);

      let params = {
        catsRequestId: catsRequestId,
        country: TENANT_ID_COUNTRY_MAP.get(tenantId),
        tenantId,
        fuelType,
      };

      log(params);
      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/requestid`,
        params
      );
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_400) {
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setCATSRequestIdSearchResult(postResponse.data);
        setIsFailedToRetrieveData(false);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setCATSRequestIdSearchResult([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setCATSRequestIdSearchResult([]);
        setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log(`error in searching CATS requestId ${catsRequestId}`);
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };
  const catsRequestIdSearchProps = {
    eventKey,
    accordionHeaderTitle: "CATS - RequestID",
    formLabel: "CATS RequestId",
    formControlName: "catsRequestId",
    onChange: setCatsRequestId,
    onButtonClick: handleCATSRequestIdSearch,
    isSearchButtonDisabled: false,
  };

  return (
    <SearchInputAccordionItem
      {...catsRequestIdSearchProps}
    ></SearchInputAccordionItem>
  );
};

export default CATSRequestIdSearch;
