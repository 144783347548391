import moment from "moment";

export const sortByDateAscending = (value1, value2) => {
  return (
    moment(value1.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf() -
    moment(value2.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf()
  );
};

export const sortByDateDescending = (value1, value2) => {
  return (
    moment(value2.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf() -
    moment(value1.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf()
  );
};

export const sortByDateNoFormatAscending = (value1, value2) => {
  return (
    moment(value1.dateCreated).utc().valueOf() -
    moment(value2.dateCreated).utc().valueOf()
  );
};

export const sortByDateNoFormatDescending = (value1, value2) => {
  return (
    moment(value2.dateCreated).utc().valueOf() -
    moment(value1.dateCreated).utc().valueOf()
  );
};

const sortByDateAscending1 = (value1, value2) => {
  return (
    moment(value1.date_created).valueOf() -
    moment(value2.date_created).valueOf()
  );
};

export const sortBySortKey = (value1, value2) => {
  let sortKey1 = value1.txnSortKey.toUpperCase();
  let sortKey2 = value2.txnSortKey.toUpperCase();
  if (sortKey1 < sortKey2) {
    return -1;
  } else {
    return 1;
  }
};

export const sortByDateCreatedOrigin = (value1, value2) => {
  return (
    moment(value1.dateCreated).valueOf() - moment(value2.dateCreated).valueOf()
  );
};
