import BootstrapTable from "react-bootstrap-table-next";
import { determineTenantName } from "../../../../utils/ALL/tenantUtils";

import paginationFactory from "react-bootstrap-table2-paginator";
import { FUEL_TYPE_ELEC } from "../../../../utils/AU/constantAU";
import {
  DEFAULT_SORTED_2,
  PAGINATION_OPTIONS_1,
} from "../../../../utils/bootStrapTableUtils";
import { SEARCH_DATA_TYPE_ACTIVE } from "../../../Search/AU/AUSearchViewUtils";
import {
  nmiSearchResultColumns,
  rowStyler,
} from "../utils/NMISearchResultUtils";
const NmiSearchResultNotWrapped = ({
  apiResponseData,
  tenantId,
  searchDataType = SEARCH_DATA_TYPE_ACTIVE,
  searchResultFuelType = FUEL_TYPE_ELEC.toLowerCase(),
  errorMessage,
  isFailedToRetrieveData,
  hideUnsolicitedColumn = true,
  hideSONumberColumn = true,
}) => {
  if (apiResponseData.length > 0) {
    return (
      <BootstrapTable
        columns={nmiSearchResultColumns(
          tenantId,
          searchDataType,
          searchResultFuelType,
          hideUnsolicitedColumn,
          hideSONumberColumn
        )}
        keyField="masterTxnId"
        data={apiResponseData}
        pagination={paginationFactory(PAGINATION_OPTIONS_1)}
        bootstrap4
        rowStyle={rowStyler}
        classes="search-result-table"
        defaultSorted={DEFAULT_SORTED_2}
        wrapperClasses="search-result-wrapper"
        headerWrapperClasses={determineTenantName()}
        data-test="search-result-table"
      ></BootstrapTable>
    );
  } else {
    return (
      <>
        {isFailedToRetrieveData ? (
          <div>{errorMessage}</div>
        ) : (
          <div>No result found</div>
        )}{" "}
      </>
    );
  }
};

export default NmiSearchResultNotWrapped;
