import { doPost, doPost1Try } from "../apiUtils";
import { getUserCountry, getUserTenantId } from "./localStorageUtils";
import { log, logStringifyJson } from "./loggingUtils";

export const PORTAL_LOG_ACTION_TYPE_NMI_SEARCH = "nmi-search";

export const logParams = {
  userId: null,
  dateInitiated: null,
  action: null,
  token: null,
  fuelType: null,
  meterPointId: null,
  dataType: null,
  txnGroup: null,
  masterTransactionType: null,
  transactionId: null,
  sordNumber: null,
  catsRequestId: null,
  filename: null,
  messageId: null,
  transactionType: null,
  tenantId: getUserTenantId(),
  country: getUserCountry(),
};

export const logPortalActivity = async (params) => {
  log("sending log params");
  const logResponse = await doPost1Try(`${process.env.REACT_APP_BASE_API_ENDPOINT}/logging/log`, params);
  //   log(`printing response:`);
  //   logStringifyJson(logResponse);
};
