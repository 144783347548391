import {
  Alert,
  Button,
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import "./D101TransactionUpload.css";
import { React, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { log } from "../../utils/ALL/loggingUtils";
import { doPostPinUpload } from "../../utils/apiUtils";
import { getOverlayDirection } from "react-bootstrap/esm/helpers";
import { TENANT_D101 } from "../../utils/ALL/tenantUtils";
const D101TransactionUpload = () => {
  const ESSENTIAL_ENERGY_PARTICIPANTS = ["CNRGYP", "CNRGYMDP"];
  const [showSingleFileAlert, setShowSingleFileAlert] = useState(false);
  const [showMultipleFileAlert, setShowMultipleFileAlert] = useState(false);

  const [pinFiles, setPinFiles] = useState([]);
  const [pinFiles1, setPinFiles1] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const validateXml = (xmlContent) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "text/xml");

      if (xmlDoc.getElementsByTagName("ase:aseXML").length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error parsing XML:", error);
      return false;
    }
  };

  const getDirection = (xmlString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const from = xmlDoc
      .getElementsByTagName("From")[0]
      .textContent.toUpperCase();
    const to = xmlDoc.getElementsByTagName("To")[0].textContent.toUpperCase();
    const messageID = xmlDoc.getElementsByTagName("MessageID")[0].textContent;

    let toReturn = {
      messageID,
      direction: "",
      participant: "",
    };

    if (ESSENTIAL_ENERGY_PARTICIPANTS.includes(from)) {
      toReturn.direction = "outgoing";
      toReturn.participant = from;
    } else {
      toReturn.direction = "incoming";
      toReturn.participant = to;
    }

    return toReturn;
  };

  const onDrop = (acceptedFiles, fileRejections, event) => {
    if (fileRejections.length > 0) {
      switch (fileRejections[0].errors[0].code) {
        case "invalid-file-prefix":
          return alert(`wrong file prefix`);
        case "file-invalid-type":
          return alert("wrong file type");
        case "invalid-filename-format":
          return alert("wrong file name format");
        case "total-file-size-exceed":
          return alert(
            "Maximum total file size will be exceeded. Lower the current file size."
          );
      }
    } else {
      if (acceptedFiles.length + pinFiles.length > 5) {
        return alert("exceed max number of files");
      } else {
        /** Do something with the files */
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => log("file reading was aborted");
          reader.onerror = () => log("file reading has failed");

          reader.onload = async () => {
            log(file.name);
            log(file.size);

            const binaryStr = reader.result;

            const fileText = binaryStr.toString();
            // log(fileText); //show the file to the console

            const { direction, participant, messageID } =
              getDirection(fileText);

            let uploadedPinFile = {
              id: "id_" + Date.now(),
              // fileName: file.name,
              fileName: messageID + ".xml",
              fileSize: file.size,
              tenantId: TENANT_D101,
            };

            let uploadedPinFile1 = {
              fileName: messageID + ".xml",
              file: fileText,
            };

            uploadedPinFile.contentType = "text/xml";
            uploadedPinFile1.contentType = "text/xml";

            // validateXml()

            uploadedPinFile.direction = direction;
            uploadedPinFile.participant = participant;
            uploadedPinFile1.direction = direction;
            uploadedPinFile1.participant = participant;

            log("uploadedPinFile: " + JSON.stringify(uploadedPinFile));

            setPinFiles((currentArray) => [...currentArray, uploadedPinFile]);
            setPinFiles1((currentArray) => [...currentArray, uploadedPinFile1]);
          };

          /** reader.readAsArrayBuffer(file); */
          reader.readAsText(file);
        });
      }
    }
  };

  const determineTotalFileSize = () => {
    let totalSize = 0;
    if (pinFiles.length > 0) {
      pinFiles.map((pinFile) => {
        totalSize = totalSize + pinFile.fileSize;
      });

      return totalSize;
    } else {
      return 0;
    }
  };

  const triggerMultiples = async (requests) => {
    let results = [];
    for (const request of requests) {
      log(request);

      let currentFileName = request.filename;

      delete request.fileName;
      if (request.url.includes("for_processing_in")) {
        log(`Uploading ${currentFileName} to for_processing_in folder`);
      } else if (request.url.includes("incoming")) {
        log(`Uploading ${currentFileName} to incoming folder`);
      } else if (request.url.includes("for_processing_out")) {
        log(`Uploading ${currentFileName} to for_processing_out folder`);
      } else if (request.url.includes("outgoing")) {
        log(`Uploading ${currentFileName} to outgoing folder`);
      }

      results.push(
        await axios
          .request(request)
          .then((res) => {
            log(res);
            const currentDoc = document.getElementById(currentFileName);
            // if (request.url.includes("for_processing_in")) {
            //   currentDoc.style.color = "white";
            //   currentDoc.style.background =
            //     "linear-gradient(90deg, rgba(117,0,192,1) 0%, rgba(117,0,192,1) 100%)";
            // } else if (request.url.includes("incoming")) {
            //   currentDoc.style.color = "white";
            //   currentDoc.style.background =
            //     "linear-gradient(90deg, rgba(117,0,192,1) 0%, rgba(255,255,255,1) 100%)";
            // }

            return res;
          })
          .catch((err) => {
            log(err);
          })
      );
    }

    let count = 0;
    for (const result of results) {
      if (result.status === 200) {
        count++;
      }
    }

    if (count === results.length) {
      // setShow(true);
      if (count === 2) {
        setShowSingleFileAlert(true);
      } else {
        setShowMultipleFileAlert(true);
      }
    } else {
      alert("Not all files are uploaded.");
    }
  };

  const submitPinFiles = async () => {
    log(pinFiles);
    try {
      if (pinFiles.length < 1) {
        return alert("No files to upload!");
      } else {
        log("Uploading file.....");
        setIsUploading(true);

        log(pinFiles);

        const postPinUploadResponse = await doPostPinUpload(
          `${process.env.REACT_APP_BASE_API_ENDPOINT}/admin/transaction-upload`,

          pinFiles
        );

        log("logging postPinUploadResponse");
        log(postPinUploadResponse);

        if (postPinUploadResponse.data.length > 0) {
          let request = [];

          postPinUploadResponse.data.map((currentDataFromAPI, index) => {
            log(index);
            let fileToUpload = pinFiles1.find(
              (o) => o.fileName === currentDataFromAPI.filename
            );
            //TODO: check sequence for the proper triggering of s3 events
            log(fileToUpload);

            request.push({
              method: "PUT",
              url: currentDataFromAPI.url,
              data: fileToUpload.file,
              headers: { "Content-Type": fileToUpload.contentType },
              filename: fileToUpload.fileName,
            });

            log("current request");
          });

          log(request);

          log("here2");

          await triggerMultiples(request);

          log("Success on uploading files");
          setIsUploading(false);

          log("Clearing files...");
          log("waiting for 3 seconds");

          setShowSingleFileAlert(true);

          setPinFiles([]);
          setPinFiles1([]);

          setTimeout(() => {
            setShowSingleFileAlert(false);
            setShowMultipleFileAlert(false);
          }, 3000);
        } else {
          log("no data returned from fileupload endpoint");
        }
      }
    } catch (error) {
      // alert(error);
      log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const fileValidator = (file) => {
    log(file);

    let currentTotalFileSize = determineTotalFileSize();

    log(currentTotalFileSize);

    let currentFileSize = file.size;
    log("Current totalFileSize: " + currentTotalFileSize);

    log("Checking file size");

    log(`Current file size ${currentFileSize}`);

    // if (currentFileSize + currentTotalFileSize > MAX_TOTAL_FILE_SIZE_BYTES) {
    //   log(
    //     `Maximum file size will be exceeded by ${
    //       currentFileSize + currentTotalFileSize - MAX_TOTAL_FILE_SIZE_BYTES
    //     }`
    //   );

    //   return {
    //     code: "total-file-size-exceed",
    //     message: `Total file size will exceed`,
    //   };
    // }
    log(
      `The total file size will be ${currentFileSize + currentTotalFileSize}`
    );

    /** TODO: uncomment the below code for checking the filename format */
    // log("file.name: " + file.name);
    // let filename = file.name;
    // let filenameWithNoExtension = filename.substring(0, filename.lastIndexOf("."));
    // log(filenameWithNoExtension);
    // if (!NZ_FILENAME_REGEX.test(filenameWithNoExtension)) {
    //   return {
    //     code: "invalid-filename-format",
    //     message: `File name has an invalid format. Please check the information for the correct file format.`,
    //   };
    // }

    let isValidPrefix = false;

    // NZ_FILENAME_PREFIXES.forEach((item) => {
    //   if (file.name.startsWith(item)) {
    //     isValidPrefix = true;
    //   }
    // });

    // if (!isValidPrefix) {
    //   log("Filename prefix is invalid.");

    //   return {
    //     code: "invalid-file-prefix",
    //     message: `File must have start with ${NZ_FILENAME_PREFIXES}`,
    //   };
    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/xml": [".xml"] },
    maxFiles: 5,
    validator: fileValidator,
  });

  const removeFile = (e) => {
    setPinFiles(
      pinFiles.filter((pinFile) => pinFile.id !== e.target.accessKey)
    );
  };

  const pinUploadViewToRender = () => {
    if (pinFiles.length > 0) {
      return fileUploadViewWithFiles();
    } else {
      return fileUploadViewNoFiles();
    }
  };

  const fileUploadViewNoFiles = () => {
    return (
      <>
        <Row className="pin-upload-menu">
          <Col className="text-center pin-upload-menu-contents-wrapper">
            <div className="pin-upload-menu-contents">
              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <Image src="/images/pin-upload-cloud-image.png"></Image>
                <div className="mt-3 mb-3">
                  Drop files here to start uploading
                </div>
                <div className="mt-3 mb-3">or</div>

                <Button
                  variant="secondary"
                  className="select-file-btn"
                >
                  Select File
                </Button>
                <div className="mt-5">
                  Supported files: <strong>XML</strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const fileUploadViewWithFiles = () => {
    return (
      <>
        <Row className="pin-upload-menu">
          <Col className="text-center pin-upload-menu-contents-wrapper">
            <div className="pin-upload-menu-contents with-pin-files">
              <div className="px-2 pin-files-list">
                {pinFiles.map((pinFile, index) => (
                  <div
                    className="form-group row pt-1 pin-files"
                    key={index}
                    id={pinFile.fileName}
                  >
                    {/* <FileEarmarkText size={30} className="file-icon" id="left" /> */}
                    <div className="col-sm-10 pin-upload-file-name">
                      {pinFile.fileName}
                    </div>
                    <div className="col-sm-2 text-end pin-upload-cancel-file">
                      <Button
                        className="remove-files-btn"
                        accessKey={pinFile.id}
                        onClick={removeFile}
                        disabled={isUploading}
                      >
                        X
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              {isUploading ? (
                <div className="spinner-div-file-uploading">
                  <Spinner
                    animation="border"
                    variant="danger"
                    className="spinner-searching"
                  />
                </div>
              ) : (
                <>
                  {pinFiles.length >= 5 ? null : (
                    <>
                      <Button className="select-additional-files-btn">
                        <div
                          {...getRootProps()}
                          className="mother-div"
                          id="select-additional-files-div"
                        >
                          <input
                            {...getInputProps()}
                            className="file-inputs"
                          />
                          Select Additional Files
                        </div>
                      </Button>

                      <div id="or">or</div>
                    </>
                  )}
                </>
              )}
              {isUploading ? null : (
                <Button
                  onClick={submitPinFiles}
                  className="upload-file-btn mt-2"
                >
                  Upload File
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Container
        fluid
        className="pin-upload-container"
      >
        <Row>
          <Col className="pin-upload-title">File Upload</Col>
        </Row>

        {pinUploadViewToRender()}
        {pinFiles.length === 0 ? (
          <Row>
            <Col className="mt-5 mb-3">
              Maximum upload: 5 files (<strong>9 MB</strong>) total
            </Col>
          </Row>
        ) : null}
      </Container>

      {showSingleFileAlert ? (
        <Alert className="alert-pin-upload-success">
          File has been uploaded
        </Alert>
      ) : null}
      {showMultipleFileAlert ? (
        <Alert className="alert-pin-upload-success">
          Files have been uploaded
        </Alert>
      ) : null}
    </>
  );
};

export default D101TransactionUpload;
