import React, { useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  Row,
  Table,
} from "react-bootstrap";
import { log } from "../../../utils/ALL/loggingUtils";
import NMIData from "../result/NMIData";
import Meters from "../result/Meters";
import Registers from "../result/Registers";
import NMIDataStreams from "../result/NMIDataStreams";
import NMIParticipantRelations from "../result/NMIParticipantRelations";
import { TENANT_D101 } from "../../../utils/ALL/tenantUtils";
import SearchViewWrapper from "../../Search/wrappers/SearchViewWrapper";
import SearchInputWrapper from "../../Search/wrappers/SearchInputWrapper";
import SearchResultWrapper from "../../Search/wrappers/SearchResultWrapper";
import SearchInputAccordionItem from "../../Search/inputs/common-components/SearchInputAccordionItem";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_401,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import { createNmiData } from "../result/NMIDataUtils";
import { createMetersData } from "../result/MatersUtils";
import { createRegistersData } from "../result/RegistersUtils";
import { createNmiDataStreamData } from "../result/NMIDataStreamsUtils";
import SearchButton from "../../Common/ReusableComponents/SearchButton/SearchButton";
import { createStandingDataResult } from "./D101StandingDataViewUtils";
import "./D101StandingDataView.css";
const D101StandingDataView = () => {
  const RESULT_VIEW_NMI_DATA = "nmi-data";
  const RESULT_VIEW_METERS = "meters";
  const RESULT_VIEW_REGISTERS = "registers";
  const RESULT_VIEW_NMI_DATA_STREAMS = "nmi-data-streams";
  const RESULT_VIEW_NMI_PARTICIPANT_RELATIONS = "nmi-participant-relations";

  // const [resultView, setResultView] = useState("");
  const [resultView, setResultView] = useState(RESULT_VIEW_NMI_DATA);
  const [sFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);

  const [nmi, setNmi] = useState("");
  const [checksum, setChecksum] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [nmiStandingData, setNmiStandingData] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [roleAssignments, setRoleAssignments] = useState({});
  const [resultNmi, setResultNmi] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [meterDetails, setMeterDetails] = useState([]);
  const [registerConfigurations, setRegisterConfigurations] = useState([]);
  const [dataStream, setDataStream] = useState([]);
  const handleNmiStandingDataSearch = async (e) => {
    e.preventDefault();

    try {
      setShowResults(false);
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      const params = {
        // nmi: "6203858434",
        nmi,
      };

      setResultNmi(nmi);

      log(params);

      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/get-standing-data`,
        params
      );
      console.log(postResponse.data);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        // setErrorMessage("Error while processing request");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_401) {
        // alert("You are no longer authorized. Please LogIn again");
        // return await Auth.signOut();
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setNmiStandingData(postResponse.data);

        const standingData = createStandingDataResult(postResponse.data);

        console.log(standingData);

        setMasterData(standingData.masterData);
        setRoleAssignments(standingData.roleAssignments);
        setMeterDetails(standingData.meter.meterDetails);
        setRegisterConfigurations(standingData.meter.registerConfigurations);
        setDataStream(standingData.dataStream);
        setShowResults(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        // setErrorMessage("Taking too long to process the request");
        // setNmiSearchResult([]);
        // setIsFailedToRetrieveData(true);
      } else {
        // log("Unhandled http status code");
        // setNmiSearchResult([]);
        // setIsFailedToRetrieveData(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const nmiStandingSearchProps = {
    eventKey: 0,
    accordionHeaderTitle: "NMI",
    formLabel: "NMI",
    formControlName: "nmi",
    onChange: setNmi,
    onButtonClick: handleNmiStandingDataSearch,
    isSearchButtonDisabled: false,
  };
  const changeActiveTab = (resultViewToCompare) => {
    return resultViewToCompare === resultView
      ? "active-standing-data-tab"
      : "inactive-standing-data-tab";
  };
  return (
    <SearchViewWrapper>
      <SearchInputWrapper
        tenantId={TENANT_D101}
        searchInputTitle={"Search Input"}
        defaultActiveKey={0}
      >
        {/* <SearchInputAccordionItem
          {...nmiStandingSearchProps}
        ></SearchInputAccordionItem>
        ; */}
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="items-search-header">
            Standing Data
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group>
                <Form.Label>NMI </Form.Label>
                <Form.Control
                  name="nmi"
                  type="text"
                  onChange={(e) => setNmi(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="search-button-div">
                <SearchButton
                  onClick={handleNmiStandingDataSearch}
                  isDisabled={false}
                ></SearchButton>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </SearchInputWrapper>
      <SearchResultWrapper
        tenantId={TENANT_D101}
        searchResultTitle={"Search Results"}
        isLoading={isLoading}
      >
        {showResults ? (
          <>
            <div>
              <Nav
                justify
                variant="tabs"
                defaultActiveKey={RESULT_VIEW_NMI_DATA}
                onSelect={(e) => setResultView(e)}
              >
                <Nav.Item
                  className={`${changeActiveTab(
                    RESULT_VIEW_NMI_DATA
                  )} standing-data-tab`}
                >
                  <Nav.Link eventKey={RESULT_VIEW_NMI_DATA}>
                    NMI {resultNmi}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={`${changeActiveTab(
                    RESULT_VIEW_METERS
                  )} standing-data-tab`}
                >
                  <Nav.Link eventKey={RESULT_VIEW_METERS}>Meter</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={`${changeActiveTab(
                    RESULT_VIEW_REGISTERS
                  )} standing-data-tab`}
                >
                  <Nav.Link eventKey={RESULT_VIEW_REGISTERS}>
                    Register Identifier
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className={`${changeActiveTab(
                    RESULT_VIEW_NMI_DATA_STREAMS
                  )} standing-data-tab`}
                >
                  <Nav.Link eventKey={RESULT_VIEW_NMI_DATA_STREAMS}>
                    NMI Data Stream
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            {resultView === RESULT_VIEW_NMI_DATA && (
              <div className="standing-data-result-wrapper">
                <br></br>
                <NMIData
                  masterData={masterData}
                  roleAssignments={roleAssignments}
                ></NMIData>
              </div>
            )}
            {resultView === RESULT_VIEW_METERS && (
              <Accordion defaultActiveKey={0}>
                {meterDetails.map((data, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={data.serialNumber}>
                      <Accordion.Header
                        className={`items-search-header standing-data-accordion-header ${
                          index % 2 === 0 ? "even" : "odd"
                        }`}
                      >
                        <strong>Serial Number: </strong>
                        {data.serialNumber}
                      </Accordion.Header>
                      <Accordion.Body className="standing-data-accordion-body">
                        <Meters key={data.serialNumber} data={data}></Meters>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )}

            {resultView === RESULT_VIEW_REGISTERS && (
              <Accordion defaultActiveKey={0}>
                {registerConfigurations.map((data, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={data.serialNumber}>
                      <Accordion.Header
                        className={`items-search-header standing-data-accordion-header ${
                          index % 2 === 0 ? "even" : "odd"
                        }`}
                      >
                        <Table borderless={true}>
                          <tbody>
                            <tr>
                              <td style={{ width: "33%" }}>
                                <strong>Register Id:</strong>{" "}
                                {
                                  JSON.parse(data.registerConfiguration)
                                    .RegisterID
                                }
                              </td>
                              <td style={{ width: "33%" }}>
                                <strong>Row Status:</strong>{" "}
                                {JSON.parse(data.registerConfiguration).Status}
                              </td>
                              <td style={{ width: "33%" }}>
                                <strong>NTC:</strong>{" "}
                                {
                                  JSON.parse(data.registerConfiguration)
                                    .NetworkTariffCode
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Header>
                      <Accordion.Body className="standing-data-accordion-body">
                        <Registers
                          key={data.serialNumber}
                          data={data}
                        ></Registers>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )}

            {resultView === RESULT_VIEW_NMI_DATA_STREAMS && (
              <Accordion defaultActiveKey={0}>
                {dataStream.map((data, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={data.serialNumber}>
                      <Accordion.Header
                        className={`items-search-header standing-data-accordion-header ${
                          index % 2 === 0 ? "even" : "odd"
                        }`}
                      >
                        <Table borderless={true}>
                          <tbody>
                            <tr>
                              <td style={{ width: "33%" }}>
                                <strong>Suffix: {data.suffix}</strong>
                              </td>
                              <td style={{ width: "33%" }}>
                                <strong>Row Status: {data.suffix}</strong>
                              </td>
                              <td>
                                <strong style={{ width: "33%" }}>
                                  Profile Name: {data.profilename}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Header>
                      <Accordion.Body className="standing-data-accordion-body">
                        <NMIDataStreams
                          key={`${data.suffix}-${data.profilename}`}
                          data={data}
                        ></NMIDataStreams>
                      </Accordion.Body>
                    </Accordion.Item>

                    // </div>
                  );
                })}
              </Accordion>
            )}
          </>
        ) : null}
      </SearchResultWrapper>
    </SearchViewWrapper>
  );
};

export default D101StandingDataView;
