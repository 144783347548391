import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { log } from "../../../../../utils/ALL/loggingUtils";

const D101SearchNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [className, setClassName] = useState("");

  useEffect(() => {
    log("change on the path");
    log("currentPath: " + currentPath);
    if (["/search", "/advanced-search"].includes(currentPath)) {
      setClassName("active-drop-down-nav");
    } else {
      setClassName("");
    }
  }, [currentPath]);
  return (
    <NavDropdown
      title="Search"
      id="header-nav-dropdown-admin"
      className={className}
    >
      <NavDropdown.Item
        as={Link}
        to="/search"
      >
        Basic Search
      </NavDropdown.Item>

      <NavDropdown.Item
        as={Link}
        to="/advanced-search"
      >
        Advanced Search
      </NavDropdown.Item>

      {/* <NavDropdown.Item as={Link} to="/process-search">
        Process
      </NavDropdown.Item> */}
      {/* <NavDropdown title="Standing Data" drop="end">
        <NavDropdown.Item as={Link} to="#">
          NMI Data
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="#">
          Meters
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="#">
          Registers
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="#">
          NMI Data Streams
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="#">
          NMI Participant Relations
        </NavDropdown.Item>
      </NavDropdown> */}
      {/* <NavDropdown.Item as={Link} to="/standing-data">
        Standing Data
      </NavDropdown.Item> */}
    </NavDropdown>
  );
};

export default D101SearchNav;
