import { getUserTenantId } from "./ALL/localStorageUtils";
import { TENANT_1001, TENANT_1002, TENANT_1003, TENANT_1005 } from "./ALL/tenantUtils";

export const portalVersion = () => {
  const tenantId = getUserTenantId();
  switch (tenantId) {
    case TENANT_1001:
      return "SAAS 1001 PL 1.0.0";
    case TENANT_1002:
      return "SAAS 1002 PL 1.0.0";
    case TENANT_1003:
      return "SAAS 1003 PL 1.1.0";
    case TENANT_1005:
      return "SAAS 1005 PL 3.1.0";
    default:
      return "";
  }
};
