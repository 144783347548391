import React, { useState } from "react";
import { FUEL_TYPE_ELEC } from "../../../utils/AU/constantAU";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_CATS_REQUEST_ID,
  SEARCH_TYPE_MESSAGE_ID,
  SEARCH_TYPE_NMI,
  SEARCH_TYPE_SORD_NUMBER,
  SEARCH_TYPE_TRANSACTION_ID,
} from "../AU/AUSearchViewUtils";
import SearchViewWrapper from "../wrappers/SearchViewWrapper";
import SearchInputWrapper from "../wrappers/SearchInputWrapper";
import NMISearch from "../inputs/NMISearch";
import SelectionSeparator from "../../Common/ReusableComponents/Separator/SelectionSeparator";
import TransactionIdSearch from "../inputs/TransactionIdSearch";
import SearchResultWrapper from "../wrappers/SearchResultWrapper";
import NmiSearchResultNotWrapped from "../../SearchResults/NMISearchResults/NMISearchResultsNotWrapped/NmiSearchResultNotWrapped";
import MessageIdSearch from "../inputs/MessageIdSearch";
import { TENANT_D101, TENANT_d101 } from "../../../utils/ALL/tenantUtils";
import { useSearchParams } from "react-router-dom";
import SORDNumberSearch from "../inputs/SORDNumberSearch";
import CATSRequestIdSearch from "../inputs/CATSRequestIdSearch";

const D101TransactionSearch = () => {
  const [nmiSearchResult, setNmiSearchResult] = useState([]);
  const [txnIdSearchResult, setTxnIdSearchResult] = useState([]);
  const [historicalNmiSearchResult, setHistoricalNmiSearchResult] = useState(
    []
  );
  const [CATSRequestIdSearchResult, setCATSRequestIdSearchResult] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchResultFuelType, setSearchResultFuelType] = useState(
    FUEL_TYPE_ELEC.toLowerCase()
  );
  const [searchDataType, setSearchDataType] = useState(SEARCH_DATA_TYPE_ACTIVE);
  const [fuelType, setFuelType] = useState(FUEL_TYPE_ELEC.toLowerCase());
  const [masterTransactionType, setMasterTransactionType] = useState("");
  const [msgIdSearchResult, setMsgIdSearchResult] = useState([]);
  const [SORDNumberSearchResult, setSORDNumberSearchResult] = useState([]);
  const [hideUnsolicitedColumn, setHideUnsolicitedColumn] = useState(true);
  const [hideSONumberColumn, setHideSONumberColumn] = useState(true);
  const searchProps = {
    setSearchDataType: setSearchDataType,
    setSearchResultFuelType: setSearchResultFuelType,
    setSearchType: setSearchType,
    setIsLoading: setIsLoading,
    setIsFailedToRetrieveData: setIsFailedToRetrieveData,
    setErrorMessage: setErrorMessage,
    tenantId: "D101",
    fuelType: fuelType,
    setHideUnsolicitedColumn,
    setHideSONumberColumn,
  };

  const RESULT_COMPONENT_BASE_PROPS = {
    hideUnsolicitedColumn,
    hideSONumberColumn,
  };

  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });

  const determineDefaultAccordion = () => {
    if (urlParams.messageId) {
      return 3;
    } else {
      return 0;
    }
  };

  return (
    <SearchViewWrapper>
      <SearchInputWrapper
        tenantId={"D101"}
        searchInputTitle={"Search"}
        defaultActiveKey={determineDefaultAccordion()}
      >
        <NMISearch
          eventKey={0}
          token={[]}
          userId={"userId"}
          setNmiSearchResult={setNmiSearchResult}
          {...searchProps}
        ></NMISearch>
        <SelectionSeparator></SelectionSeparator>
        <TransactionIdSearch
          eventKey={1}
          setTxnIdSearchResult={setTxnIdSearchResult}
          {...searchProps}
        ></TransactionIdSearch>
        <SelectionSeparator></SelectionSeparator>
        <CATSRequestIdSearch
          eventKey={2}
          setCATSRequestIdSearchResult={setCATSRequestIdSearchResult}
          fuelType={"elec"}
          setSearchResultFuelType={setSearchResultFuelType}
          {...searchProps}
        ></CATSRequestIdSearch>
        <SelectionSeparator></SelectionSeparator>
        <SORDNumberSearch
          eventKey={3}
          setSORDNumberSearchResult={setSORDNumberSearchResult}
          fuelType={"elec"}
          setSearchResultFuelType={setSearchResultFuelType}
          {...searchProps}
        ></SORDNumberSearch>
        {/* <MessageIdSearch
          eventKey={2}
          setMsgIdSearchResult={setMsgIdSearchResult}
          setIsLoading={setIsLoading}
          setIsFailedToRetrieveData={setIsFailedToRetrieveData}
          setSearchDataType={setSearchDataType}
          setSearchType={setSearchType}
          tenantId={TENANT_D101}
          setErrorMessage={setErrorMessage}
        ></MessageIdSearch> */}
      </SearchInputWrapper>
      <SearchResultWrapper
        tenantId={"D101"}
        searchResultTitle={"Search Results"}
        isLoading={isLoading}
      >
        {searchType === SEARCH_TYPE_NMI && (
          <NmiSearchResultNotWrapped
            apiResponseData={nmiSearchResult}
            tenantId={"D101"}
            isFailedToRetrieveData={isFailedToRetrieveData}
            errorMessage={errorMessage}
            {...RESULT_COMPONENT_BASE_PROPS}
          ></NmiSearchResultNotWrapped>
        )}
        {searchType === SEARCH_TYPE_TRANSACTION_ID && (
          <NmiSearchResultNotWrapped
            apiResponseData={txnIdSearchResult}
            tenantId={"D101"}
            isFailedToRetrieveData={isFailedToRetrieveData}
            errorMessage={errorMessage}
            {...RESULT_COMPONENT_BASE_PROPS}
          ></NmiSearchResultNotWrapped>
        )}
        {searchType === SEARCH_TYPE_SORD_NUMBER && (
          <NmiSearchResultNotWrapped
            apiResponseData={SORDNumberSearchResult}
            tenantId={"D101"}
            isFailedToRetrieveData={isFailedToRetrieveData}
            errorMessage={errorMessage}
            {...RESULT_COMPONENT_BASE_PROPS}
          ></NmiSearchResultNotWrapped>
        )}
        {searchType === SEARCH_TYPE_CATS_REQUEST_ID && (
          <NmiSearchResultNotWrapped
            apiResponseData={CATSRequestIdSearchResult}
            isFailedToRetrieveData={isFailedToRetrieveData}
            errorMessage={errorMessage}
            tenantId={"D101"}
            {...RESULT_COMPONENT_BASE_PROPS}
          ></NmiSearchResultNotWrapped>
        )}
        {/* {searchType === SEARCH_TYPE_MESSAGE_ID && (
          <NmiSearchResultNotWrapped
            apiResponseData={msgIdSearchResult}
            tenantId={"D101"}
            isFailedToRetrieveData={isFailedToRetrieveData}
            errorMessage={errorMessage}
          ></NmiSearchResultNotWrapped>
        )} */}
      </SearchResultWrapper>
    </SearchViewWrapper>
  );
};

export default D101TransactionSearch;
