export const createNmiData = (xmlJson) => {
  console.log("create master data called");

  const nmiStandingData =
    xmlJson["ase:aseXML"]["Transactions"][0]["Transaction"][0][
      "NMIStandingDataResponse"
    ][0]["NMIStandingData"][0];

  //make the "MasterData" common
  // console.log(nmiStandingData?.["MasterData"]?.[0]?.["JurisdictionCode"]);

  const valuesToExtract = {
    //firstColumn Data
    jurisdictionCode:
      nmiStandingData?.["MasterData"]?.[0]?.["JurisdictionCode"]?.[0] ?? "-",
    nmiClassificationCode:
      nmiStandingData?.["MasterData"]?.[0]?.["NMIClassificationCode"]?.[0] ??
      "-",
    distributionLossFactorCode:
      nmiStandingData?.["MasterData"]?.[0]?.[
        "DistributionLossFactorCode"
      ]?.[0] ?? "-",
    fromDate: nmiStandingData?.["MasterData"]?.[0]?.["FromDate"]?.[0] ?? "-",
    customerClassificationCode:
      nmiStandingData?.["MasterData"]?.[0]?.[
        "CustomerClassificationCode"
      ]?.[0] ?? "-",
    customerThresholdCode:
      nmiStandingData?.["MasterData"]?.[0]?.["CustomerThresholdCode"]?.[0] ??
      "-",
    connectionConfiguration:
      nmiStandingData?.["MasterData"]?.[0]?.["ConnectionConfiguration"]?.[0] ??
      "-",
    nmiStatus: nmiStandingData?.["MasterData"]?.[0]?.["Status"]?.[0] ?? "-",
    postCode:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "PostCode"
      ]?.[0] ?? "-",

    //2nd column data
    rowStatus: nmiStandingData?.["MasterData"]?.[0]?.["Status"]?.[0] ?? "-",
    // lastSeen
    // inactiveForFrmps
    transmissionNodeIdentifier:
      nmiStandingData?.["MasterData"]?.[0]?.[
        "TransmissionNodeIdentifier"
      ]?.[0] ?? "-",
    //   transmissionNodeIdentifier2
    //   sharedIsolationPointFlag
    //       parentEmbeddedNetworkIdentifier
    //  childEmbeddedNetworkIdentifier
    //  aggregate
    //  feederClass
    geocodedNationalAddressFilePersistentIdentifier:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "GNAFPID"
      ]?.[0] ?? "-",
    // sectionNumber
    //  depositedPlanNumber
    // lastConsumerChangeDate

    /* 3rd column */
    //     buildingOrPropertyName
    lotNumber:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["Lot"]?.[0]?.["LotNumber"]?.[0] ?? "-",
    flatOrUnitNumber:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["FlatOrUnit"]?.[0]?.["FlatOrUnitNumber"]?.[0],
    flatOrUnitType:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["FlatOrUnit"]?.[0]?.["FlatOrUnitType"]?.[0] ?? "-",

    // FloorOrLevelNumber
    // floorOrLevelType
    houseNumber:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["House"]?.[0]?.["HouseNumber"]?.[0] ?? "-",
    // houseNumberSuffix
    // houseNumberTo
    // HouseNumberToSuffix

    /** 4th column */
    streetName:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["Street"]?.[0]?.["StreetName"]?.[0] ?? "-",
    streetType:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StructuredAddress"
      ]?.[0]?.["Street"]?.[0]?.["StreetType"]?.[0] ?? "-",
    //  streetSuffix
    suburbOrPlaceOrLocality:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "SuburbOrPlaceOrLocality"
      ]?.[0] ?? "-",

    // locationDescriptor
    // postCode:
    //   nmiStandingData?.["MasterData"]?.[0]?.["Address"][0]["PostCode"][0] ?? "-",
    //  deliveryPointIdentifier
    stateOrTerritory:
      nmiStandingData?.["MasterData"]?.[0]?.["Address"]?.[0]?.[
        "StateOrTerritory"
      ]?.[0] ?? "-",
    //5th column
    //  addressLine1
    //   addressLine2
    //   addressLine3
  };

  console.log(valuesToExtract);

  return valuesToExtract;
};
