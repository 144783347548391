export const TENANT_ID = "d101";
export const AU_STATES = [
  "AAT",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export const FLAT_TYPES = [
  { key: "FLAT", value: "Flat" },
  { key: "ANT", value: "Antenna" },
  { key: "ATM", value: "Automated Teller Machine" },
  { key: "BBQ", value: "Barbeque" },
  { key: "BLCK", value: "Block" },
  { key: "BTSD", value: "Boatshed" },
  { key: "BLDG", value: "Building" },
  { key: "BNGW", value: "Bungalow" },
  { key: "CAGE", value: "Cage" },
  { key: "CARP", value: "Car Park" },
  { key: "CARS", value: "Car Space" },
  { key: "CLUB", value: "Club" },
  { key: "COOL", value: "Coolroom" },
  { key: "DUPL", value: "Duplex" },
  { key: "FCTY", value: "Factory" },
  { key: "GRGE", value: "Garage" },
  { key: "HALL", value: "Hall" },
  { key: "LBBY", value: "Lobby" },
  { key: "LOFT", value: "Loft" },
  { key: "LOT", value: "Lot" },
  { key: "LSE", value: "Lease" },
  { key: "MBTH", value: "Master Berth" },
  { key: "OFFC", value: "Office" },
  { key: "REAR", value: "Rear" },
  { key: "ROOM", value: "Room" },
  { key: "RESV", value: "Reserve" },
  { key: "SEC", value: "Section" },
  { key: "SHRM", value: "Showroom" },
  { key: "SIGN", value: "Sign" },
  { key: "STLL", value: "Stall" },
  { key: "STOR", value: "Store" },
  { key: "STR", value: "Strata Unit" },
  { key: "SUBS", value: "Substation" },
  { key: "TNCY", value: "Tenancy" },
  { key: "TWR", value: "Tower" },
  { key: "UNIT", value: "Unit" },
  { key: "VLT", value: "Vault" },
  { key: "WHSE", value: "Warehouse" },
  { key: "WKSH", value: "Workshop" },
];
export const FLOOR_TYPES = [
  { key: "LB", value: "Lobby" },
  { key: "OD", value: "Observation Deck" },
  { key: "P", value: "Parking" },
  { key: "RT", value: "Rooftop" },
  { key: "SB", value: "Sub-basement" },
  { key: "B", value: "Bedroom" },
  { key: "FL", value: "Floor" },
  { key: "G", value: "Garage" },
  { key: "L", value: "Lobby" },
  { key: "LG", value: "Lounge" },
  { key: "M", value: "Meeting Room" },
];

// export const
export const STREET_TYPES = [
  { key: "ALLY", value: "ALLEY" },
  { key: "ALWY", value: "ALLEYWAY" },
  { key: "AMBL", value: "AMBLE" },
  { key: "ANCG", value: "ANCHORAGE" },
  { key: "APP", value: "APPROACH" },
  { key: "ARC", value: "ARCADE" },
  { key: "ART", value: "ARTERY" },
  { key: "AVE", value: "AVENUE" },
  { key: "BASN", value: "BASIN" },
  { key: "BCH", value: "BEACH" },
  { key: "BEND", value: "BEND" },
  { key: "BLK", value: "BLOCK" },
  { key: "BVD", value: "BOULEVARD" },
  { key: "BRCE", value: "BRACE" },
  { key: "BRAE", value: "BRAE" },
  { key: "BRK", value: "BREAK" },
  { key: "BDGE", value: "BRIDGE" },
  { key: "BDWY", value: "BROADWAY" },
  { key: "BROW", value: "BROW" },
  { key: "BYPA", value: "BYPASS" },
  { key: "BYWY", value: "BYWAY" },
  { key: "CAUS", value: "CAUSEWAY" },
  { key: "CTR", value: "CENTRE" },
  { key: "CNWY", value: "CENTREWAY" },
  { key: "CH", value: "CHASE" },
  { key: "CIR", value: "CIRCLE" },
  { key: "CLT", value: "CIRCLET" },
  { key: "CCT", value: "CIRCUIT" },
  { key: "CRCS", value: "CIRCUS" },
  { key: "CL", value: "CLOSE" },
  { key: "CLDE", value: "COLONNADE" },
  { key: "CMMN", value: "COMMON" },
  { key: "CON", value: "CONCOURSE" },
  { key: "CPS", value: "COPSE" },
  { key: "CNR", value: "CORNER" },
  { key: "CSO", value: "CORSO" },
  { key: "CT", value: "COURT" },
  { key: "CTYD", value: "COURTYARD" },
  { key: "COVE", value: "COVE" },
  { key: "CRES", value: "CRESCENT" },
  { key: "CRST", value: "CREST" },
  { key: "CRSS", value: "CROSS" },
  { key: "CRSG", value: "CROSSING" },
  { key: "CRD", value: "CROSSROAD" },
  { key: "COWY", value: "CROSSWAY" },
  { key: "CUWY", value: "CRUISEWAY" },
  { key: "CDS", value: "CUL-DE-SAC" },
  { key: "CTTG", value: "CUTTING" },
  { key: "DALE", value: "DALE" },
  { key: "DELL", value: "DELL" },
  { key: "DEVN", value: "DEVIATION" },
  { key: "DIP", value: "DIP" },
  { key: "DSTR", value: "DISTRIBUTOR" },
  { key: "DR", value: "DRIVE" },
  { key: "DRWY", value: "DRIVEWAY" },
  { key: "EDGE", value: "EDGE" },
  { key: "ELB", value: "ELBOW" },
  { key: "END", value: "END" },
  { key: "ENT", value: "ENTRANCE" },
  { key: "ESP", value: "ESPLANADE" },
  { key: "EST", value: "ESTATE" },
  { key: "EXP", value: "EXPRESSWAY" },
  { key: "EXTN", value: "EXTENSION" },
  { key: "FAWY", value: "FAIRWAY" },
  { key: "FTRK", value: "FIRE" },
  { key: "FITR", value: "FIRETRAIL" },
  { key: "FLAT", value: "FLAT" },
  { key: "FOLW", value: "FOLLOW" },
  { key: "FTWY", value: "FOOTWAY" },
  { key: "FSHR", value: "FORESHORE" },
  { key: "FORM", value: "FORMATION" },
  { key: "FWY", value: "FREEWAY" },
  { key: "FRNT", value: "FRONT" },
  { key: "FRTG", value: "FRONTAGE" },
  { key: "GAP", value: "GAP" },
  { key: "GDN", value: "GARDEN" },
  { key: "GTE", value: "GATE" },
  { key: "GDNS", value: "GARDENS" },
  { key: "GTES", value: "GATES" },
  { key: "GLD", value: "GLADE" },
  { key: "GLEN", value: "GLEN" },
  { key: "GRA", value: "GRANGE" },
  { key: "GRN", value: "GREEN" },
  { key: "GRND", value: "GROUND" },
  { key: "GR", value: "GROVE" },
  { key: "GLY", value: "GULLY" },
  { key: "HTS", value: "HEIGHTS" },
  { key: "HRD", value: "HIGHROAD" },
  { key: "HWY", value: "HIGHWAY" },
  { key: "HILL", value: "HILL" },
  { key: "INTG", value: "INTERCHANGE" },
  { key: "INTN", value: "INTERSECTION" },
  { key: "JNC", value: "JUNCTION" },
  { key: "KEY", value: "KEY" },
  { key: "LDG", value: "LANDING" },
  { key: "LANE", value: "LANE" },
  { key: "LNWY", value: "LANEWAY" },
  { key: "LEES", value: "LEES" },
  { key: "LINE", value: "LINE" },
  { key: "LINK", value: "LINK" },
  { key: "LT", value: "LITTLE" },
  { key: "LKT", value: "LOOKOUT" },
  { key: "LOOP", value: "LOOP" },
  { key: "LWR", value: "LOWER" },
  { key: "MALL", value: "MALL" },
  { key: "MNDR", value: "MEANDER" },
  { key: "MEW", value: "MEW" },
  { key: "MEWS", value: "MEWS" },
  { key: "MWY", value: "MOTORWAY" },
  { key: "MT", value: "MOUNT" },
  { key: "NOOK", value: "NOOK" },
  { key: "OTLK", value: "OUTLOOK" },
  { key: "PDE", value: "PARADE" },
  { key: "PARK", value: "PARK" },
  { key: "PKLD", value: "PARKLANDS" },
  { key: "PKWY", value: "PARKWAY" },
  { key: "PART", value: "PART" },
  { key: "PASS", value: "PASS" },
  { key: "PATH", value: "PATH" },
  { key: "PHWY", value: "PATHWAY" },
  { key: "PIAZ", value: "PIAZZA" },
  { key: "PL", value: "PLACE" },
  { key: "PLAT", value: "PLATEAU" },
  { key: "PLZA", value: "PLAZA" },
  { key: "PKT", value: "POCKET" },
  { key: "PNT", value: "POINT" },
  { key: "PORT", value: "PORT" },
  { key: "PROM", value: "PROMENADE" },
  { key: "QUAD", value: "QUAD" },
  { key: "QDGL", value: "QUADRANGLE" },
  { key: "QDRT", value: "QUADRANT" },
  { key: "QY", value: "QUAY" },
  { key: "QYS", value: "QUAYS" },
  { key: "RMBL", value: "RAMBLE" },
  { key: "RAMP", value: "RAMP" },
  { key: "RNGE", value: "RANGE" },
  { key: "RCH", value: "REACH" },
  { key: "RES", value: "RESERVE" },
  { key: "REST", value: "REST" },
  { key: "RTT", value: "RETREAT" },
  { key: "RIDE", value: "RIDE" },
  { key: "RDGE", value: "RIDGE" },
  { key: "RGWY", value: "RIDGEWAY" },
  { key: "ROWY", value: "RIGHT" },
  { key: "RING", value: "RING" },
  { key: "RISE", value: "RISE" },
  { key: "RVR", value: "RIVER" },
  { key: "RVWY", value: "RIVERWAY" },
  { key: "RVRA", value: "RIVIERA" },
  { key: "RD", value: "ROAD" },
  { key: "RDS", value: "ROADS" },
  { key: "RDSD", value: "ROADSIDE" },
  { key: "RDWY", value: "ROADWAY" },
  { key: "RNDE", value: "RONDE" },
  { key: "RSBL", value: "ROSEBOWL" },
  { key: "RTY", value: "ROTARY" },
  { key: "RND", value: "ROUND" },
  { key: "RTE", value: "ROUTE" },
  { key: "ROW", value: "ROW" },
  { key: "RUE", value: "RUE" },
  { key: "RUN", value: "RUN" },
  { key: "SWY", value: "SERVICE" },
  { key: "SDNG", value: "SIDING" },
  { key: "SLPE", value: "SLOPE" },
  { key: "SND", value: "SOUND" },
  { key: "SPUR", value: "SPUR" },
  { key: "SQ", value: "SQUARE" },
  { key: "STRS", value: "STAIRS" },
  { key: "SHWY", value: "STATE" },
  { key: "STPS", value: "STEPS" },
  { key: "STRA", value: "STRAND" },
  { key: "ST", value: "STREET" },
  { key: "STRP", value: "STRIP" },
  { key: "SBWY", value: "SUBWAY" },
  { key: "TARN", value: "TARN" },
  { key: "TCE", value: "TERRACE" },
  { key: "THOR", value: "THOROUGHFARE" },
  { key: "TLWY", value: "TOLLWAY" },
  { key: "TOP", value: "TOP" },
  { key: "TOR", value: "TOR" },
  { key: "TWRS", value: "TOWERS" },
  { key: "TRK", value: "TRACK" },
  { key: "TRL", value: "TRAIL" },
  { key: "TRLR", value: "TRAILER" },
  { key: "TRI", value: "TRIANGLE" },
  { key: "TKWY", value: "TRUNKWAY" },
  { key: "TURN", value: "TURN" },
  { key: "UPAS", value: "UNDERPASS" },
  { key: "UPR", value: "UPPER" },
  { key: "VALE", value: "VALE" },
  { key: "VDCT", value: "VIADUCT" },
  { key: "VIEW", value: "VIEW" },
  { key: "VLLS", value: "VILLAS" },
  { key: "VSTA", value: "VISTA" },
  { key: "WADE", value: "WADE" },
  { key: "WALK", value: "WALK" },
  { key: "WKWY", value: "WALKWAY" },
  { key: "WAY", value: "WAY" },
  { key: "WHRF", value: "WHARF" },
  { key: "WYND", value: "WYND" },
  { key: "YARD", value: "YARD" },
];
