import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  SubTitle,
} from "chart.js";
import { useState } from "react";
import { Line } from "react-chartjs-2";
// import "./GraphView.css";
import "./AUGraphView.css";
import { log } from "../../../../../utils/ALL/loggingUtils";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, SubTitle);
const AUGraphView = ({
  incomingSubtitleToUse,
  outgoingSubtitleToUse,
  dataTobeShownIncoming,
  dataTobeShownOutgoing,
}) => {
  const [incomingGraphData, setIncomingGraphData] = useState(dataTobeShownIncoming);
  const [outgoingGraphData, setOutgoingGraphData] = useState(dataTobeShownOutgoing);
  const [incomingSubtitle, setIncomingSubtitle] = useState(incomingSubtitleToUse);
  const [outgoingSubtitle, setOutgoingSubtitle] = useState(outgoingSubtitleToUse);

  const handleClickLegend = (event, legendItem, legend) => {
    log(legend.ctx.canvas.className);
    let isOutboundGraph = false;
    let isInboundGraph = false;
    if (legend.ctx.canvas.className.includes("-outbound-")) {
      log("outbound graph");
      isOutboundGraph = true;
    } else if (legend.ctx.canvas.className.includes("-inbound-")) {
      log("inbound graph");
      isInboundGraph = true;
    } else {
      log("unhandled graph");
      isOutboundGraph = false;
      isInboundGraph = false;
    }

    // log(JSON.stringify(legendItem));
    legendItem.hidden = false;

    //TODO: if currently all are true, the selected label will be the one shown, and others will be hidden
    if (isAllShown(isOutboundGraph, isInboundGraph)) {
      log("all are shown, others will be hidden except " + legendItem.text);

      if (isOutboundGraph) {
        outgoingGraphData.datasets.map((dataset) => {
          if (dataset.label != legendItem.text) {
            dataset.hidden = true;
          }
        });

        event.chart.config.data.datasets = outgoingGraphData.datasets;
        return event.chart.update("none");
      } else if (isInboundGraph) {
        incomingGraphData.datasets.map((dataset) => {
          if (dataset.label != legendItem.text) {
            dataset.hidden = true;
          }
        });

        event.chart.config.data.datasets = incomingGraphData.datasets;
        return event.chart.update("none");
      }
    }

    //TODO: if selected is only one that is not hidden, unhide all
    if (isSelectedLegendShown(legendItem, isOutboundGraph, isInboundGraph)) {
      if (isOutboundGraph) {
        log(legendItem.text + " is  shown");
        outgoingGraphData.datasets.map((dataset) => {
          dataset.hidden = false;
        });

        event.chart.config.data.datasets = outgoingGraphData.datasets;
        return event.chart.update("none");
      } else if (isInboundGraph) {
        log(legendItem.text + " is  shown");
        incomingGraphData.datasets.map((dataset) => {
          dataset.hidden = false;
        });

        event.chart.config.data.datasets = incomingGraphData.datasets;
        return event.chart.update("none");
      }
    } else {
      if (isOutboundGraph) {
        log(legendItem.text + " is  not shown");
        outgoingGraphData.datasets.map((dataset) => {
          if (dataset.label != legendItem.text) {
            dataset.hidden = true;
          }

          if (dataset.label === legendItem.text) {
            dataset.hidden = false;
          }
        });

        event.chart.config.data.datasets = outgoingGraphData.datasets;
        return event.chart.update("none");
      } else if (isInboundGraph) {
        log(legendItem.text + " is  not shown");
        incomingGraphData.datasets.map((dataset) => {
          if (dataset.label != legendItem.text) {
            dataset.hidden = true;
          }

          if (dataset.label === legendItem.text) {
            dataset.hidden = false;
          }
        });

        event.chart.config.data.datasets = incomingGraphData.datasets;
        return event.chart.update("none");
      }
    }
  };

  const isSelectedLegendShown = (legendItem, isOutboundGraph, isInboundGraph) => {
    if (isOutboundGraph) {
      log("inside isSelectedLegendShown");

      let isSelectedLegendShown = false;
      log(outgoingGraphData);

      outgoingGraphData.datasets.map((dataset) => {
        if (dataset.label === legendItem.text) {
          log("dataset.label -> " + dataset.label);
          log("legendItem.text -> " + legendItem.text);
          log(dataset);

          if (dataset.hidden === true) {
            //this means that the legend is currently not showing
            log("dataset.hidden -> " + dataset.hidden);

            isSelectedLegendShown = false;
          } else {
            //this means that the legend is currently showing
            log("dataset.hidden -> " + dataset.hidden);
            isSelectedLegendShown = true;
          }
        }
      });

      log("isSelectedLegendShown ->" + isSelectedLegendShown);

      return isSelectedLegendShown;
    } else if (isInboundGraph) {
      log("inside isSelectedLegendShown");

      let isSelectedLegendShown = false;
      log(incomingGraphData);

      incomingGraphData.datasets.map((dataset) => {
        if (dataset.label === legendItem.text) {
          log("dataset.label -> " + dataset.label);
          log("legendItem.text -> " + legendItem.text);
          log(dataset);

          if (dataset.hidden === true) {
            //this means that the legend is currently not showing
            log("dataset.hidden -> " + dataset.hidden);

            isSelectedLegendShown = false;
          } else {
            //this means that the legend is currently showing
            log("dataset.hidden -> " + dataset.hidden);
            isSelectedLegendShown = true;
          }
        }
      });

      log("isSelectedLegendShown ->" + isSelectedLegendShown);

      return isSelectedLegendShown;
    }
  };

  //check if all of there are shown
  const isAllShown = (isOutboundGraph, isInboundGraph) => {
    if (isOutboundGraph) {
      let noOfDatasets = 0;
      let currentShown = 0;
      noOfDatasets = outgoingGraphData.datasets.length;
      currentShown = 0;
      outgoingGraphData.datasets.map((dataset) => {
        if (dataset.hidden === false) {
          currentShown++;
        }
      });

      return noOfDatasets === currentShown;
    } else if (isInboundGraph) {
      let noOfDatasets = 0;
      let currentShown = 0;
      noOfDatasets = incomingGraphData.datasets.length;
      currentShown = 0;
      incomingGraphData.datasets.map((dataset) => {
        if (dataset.hidden === false) {
          currentShown++;
        }
      });

      return noOfDatasets === currentShown;
    }
  };

  const incomingOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        onClick: handleClickLegend,
      },
      subtitle: {
        display: true,
        text: incomingSubtitle,
        position: "bottom",
      },
      title: {
        display: true,
        text: "Inbound Messages",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    maintainAspectRatio: false,
  };
  const outgoingOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        onClick: handleClickLegend,
      },
      subtitle: {
        display: true,
        text: outgoingSubtitle,
        position: "bottom",
      },
      title: {
        display: true,
        text: "Outbound Messages",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    maintainAspectRatio: false,
  };
  return (
    <>
      <div style={{ width: "1000px", height: "300px" }}>
        {Object.keys(incomingGraphData).length > 0 ? (
          <Line className="1005-inbound-chart" height={300} options={incomingOptions} data={incomingGraphData} />
        ) : null}
      </div>

      <div style={{ width: "1000px", height: "300px" }}>
        {Object.keys(outgoingGraphData).length > 0 ? (
          <Line
            className="1005-outbound-chart"
            height={300}
            options={outgoingOptions}
            data={outgoingGraphData}
            datasetIdKey="outgoing-graph"
          />
        ) : null}
      </div>
    </>
  );
};

export default AUGraphView;
