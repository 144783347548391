import React from "react";
import { determineTenantNamePassId } from "../../../utils/ALL/tenantUtils";
import { Col, Container, Row, Spinner } from "react-bootstrap";

const MasterTxnDetailsViewWrapper = ({ tenantId, isFetchingData, isApiError, associatedTransactions, children }) => {
  return (
    <div className="background-image-wrapper" id={determineTenantNamePassId(tenantId)}>
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col className="search-results-title" id={determineTenantNamePassId(tenantId)}>
            Transaction Details
          </Col>
        </Row>

        {!isFetchingData && isApiError ? (
          <Row className="spinner-div master-txn-view-spinner-wrapper">Error on retrieving data.</Row>
        ) : (
          <>
            {isFetchingData ? (
              <Row className="spinner-div master-txn-view-spinner-wrapper">
                <Col className="spinner-col">
                  <Spinner animation="border" variant="danger" className="spinner-master-txn-viewer" />
                </Col>
              </Row>
            ) : (
              <>{associatedTransactions.length > 0 ? <> {children}</> : null}</>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default MasterTxnDetailsViewWrapper;
