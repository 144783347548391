import React from "react";
import { Col, Row, Table } from "react-bootstrap";

//TODO: find a smarted way to do tdis
const NMIData = ({ masterData, roleAssignments }) => {
  const firstColumnMap = new Map([
    ["Status:", masterData.status],
    ["Supply dates:", masterData.supplyDate],
    ["LNSP:", roleAssignments.lnsp],
    ["Meter Coordinator:", roleAssignments.mpc], //check with others
    ["Meter Provider:", roleAssignments.mpb], //check with others
    ["MDP:", roleAssignments.mdp],
    ["Supplier ID:", roleAssignments.supplierId],
    [
      "Address:",
      `${masterData.flatorunitnumber ? masterData.flatorunitnumber + "/" : ""}${
        masterData.housenumber
      } ${masterData.streetname} ${masterData.streettype} ${
        masterData.stateorterritory
      } ${masterData.postcode}`,
    ],
    ["Hazards:", masterData.hazards],
    ["Active From:", masterData.fromDate],
    ["Active To:", masterData.toDate],
  ]);

  const secondColumnMap = new Map([
    ["Jurisdiction:", masterData.jurisdictioncode],
    ["Energisation Status:", masterData.energisationStatus],
    ["NMI Classification:", masterData.nmiclassificationcode],
    ["Customer Classification:", masterData.customerclassificationcode],
    ["Customer Threshold:", masterData.customerThreshold],
    ["Distribution Loss Factor:", masterData.distributionlossfactorcode],
    ["Access Details:", masterData.accessdata],
    ["Feeder Class:", masterData.feederclass],
    ["Connection Configuration:", masterData.connectionconfiguration],
    ["Shared Isolation Point Flag:", masterData.sharedisolationpointflag],
  ]);
  // const firstColumnMap = new Map([
  //   ["Sequence Number:", data.sequenceNumber],
  //   ["Maintained At:", data.maintainedAt],
  //   ["Created At:", data.createdAt],
  //   ["Jurisdiction:", data.jurisdictionCode],
  //   ["Energisation status:", data.energisationStatus],
  //   ["NMI classification:", data.nmiClassificationCode],
  //   ["Customer classification:", data.customerClassificationCode],
  //   ["Customer threshold:", data.customerThresholdCode],
  //   ["Distribution Loss Factor:", data.distributionLossFactorCode],
  //   ["Connection Configuration:", data.connectionConfiguration],
  //   [
  //     "Meter Malfunction Exemption Number:",
  //     data.meterMalfunctionExemptionNumber,
  //   ],
  //   [
  //     "Meter Malfunction Exemption Expires At:",
  //     data.meterMalfunctionExemptionExpiresAt,
  //   ],
  // ]);

  // const secondColumnMap = new Map([
  //   ["Row Status:", data.rowStatus],
  //   ["Last Seen:", data.lastSeen],
  //   ["Inactivated for FRMPs:", data.inactiveForFrmps],
  //   ["Transmission Node Identifier:", data.transmissionNodeIdentifier],
  //   ["Transmission Node Identifier 2:", data.transmissionNodeIdentifier2],
  //   ["Shared Isolation Point Flag:", data.sharedIsolationPointFlag],
  //   [
  //     "Parent Embedded Network Identifier:",
  //     data.parentEmbeddedNetworkIdentifier,
  //   ],
  //   ["Child Embedded Network Identifier:", data.childEmbeddedNetworkIdentifier],
  //   ["Aggregate:", data.aggregate],
  //   ["Feeder Class:", data.feederClass],
  //   [
  //     "Geocoded National Address File Persistent Identifier:",
  //     data.geocodedNationalAddressFilePersistentIdentifier,
  //   ],
  //   ["Section Number:", data.sectionNumber],
  //   ["Deposited Plan Number:", data.depositedPlanNumber],
  //   ["Last Consumer Change Date:", data.lastConsumerChangeDate],
  // ]);

  // const thirdColumnMap = new Map([
  //   ["Building or Property Name:", data.buildingOrPropertyName],
  //   ["Lot Number:", data.lotNumber],
  //   ["Flat or Unit Number:", data.flatOrUnitNumber],
  //   ["Flat or Unit Type:", data.flatOrUnitType],
  //   ["Floor or Level Number:", data.floorOrLevelNumber],
  //   ["Floor or Level Type:", data.floorOrLevelType],
  //   ["House Number:", data.houseNumber],
  //   ["House Number Suffix:", data.houseNumberSuffix],
  //   ["House Number To:", data.houseNumberTo],
  //   ["House Number To Suffix:", data.houseNumberToSuffix],
  // ]);

  // const fourthColum = new Map([
  //   ["Street Name:", data.streetName],
  //   ["Street Type:", data.streetType],
  //   ["Street Suffix:", data.streetSuffix],
  //   ["Suburb or Place or Locality:", data.suburbOrPlaceOrLocality],
  //   ["Location Descriptor:", data.locationDescriptor],
  //   ["Post Code:", data.postCode],
  //   ["Delivery Point Identifier:", data.deliveryPointIdentifier],
  // ]);

  // const fifthColum = new Map([
  //   ["Address Line 1:", data.addressLine1],
  //   ["Address Line 2:", data.addressLine2],
  //   ["Address Line 3:", data.addressLine3],
  // ]);
  return (
    <>
      <Row>
        <Col>
          <Table>
            {Array.from(firstColumnMap).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{key}</strong>
                </td>
                <td>{value}</td>
              </tr>
            ))}
          </Table>
        </Col>
        <Col>
          <Table>
            {Array.from(secondColumnMap).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{key}</strong> {value}
                </td>
              </tr>
            ))}
          </Table>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Table>
            {Array.from(thirdColumnMap).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{key}</strong> {value}
                </td>
              </tr>
            ))}
          </Table>
        </Col>
        <Col>
          <Table>
            {Array.from(fourthColum).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{key}</strong> {value}
                </td>
              </tr>
            ))}
          </Table>
        </Col>
        <Col>
          <Table>
            {Array.from(fifthColum).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{key}</strong> {value}
                </td>
              </tr>
            ))}
          </Table>
        </Col>
      </Row> */}
    </>
  );
};

export default NMIData;
