import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  DATE_FORMAT_4,
  DATE_FORMAT_5,
  DATE_FORMAT_6,
  determineIfAestOrAedt,
  formatDateTimeTypeA,
  getCurrentAuTime,
} from "../../../../../utils/dateTimeUtils";
import { Spinner } from "react-bootstrap";
import { TENANT_1001, determineTenantName } from "../../../../../utils/ALL/tenantUtils";
import paginationFactory from "react-bootstrap-table2-paginator";
import { PAGINATION_OPTIONS_1 } from "../../../../../utils/bootStrapTableUtils";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { FUEL_TYPE_ELEC } from "../../../../../utils/AU/constantAU";
// import { SO_STATUS } from "../../AU/utils/ReportTypeValues";
import { getUserCountry, getUserTenantId } from "../../../../../utils/ALL/localStorageUtils";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../../../utils/apiUtils";
import { log } from "../../../../../utils/ALL/loggingUtils";
const { ExportCSVButton } = CSVExport;
const ServiceOrderStatusView = ({ tenantId }) => {
  //TODO: move to a utils folder
  const soAgeFormatter = (cell, row) => {
    const currentAuTime = moment.tz("Australia/Sydney").utc(true);
    const dateFromTable = moment.utc(cell).tz("Australia/Sydney");

    const timeDifference = currentAuTime - dateFromTable;
    const duration = moment.duration(timeDifference);

    //TODO: create constant for the date format
    return moment.utc(duration.asMilliseconds()).format("DD HH:mm:ss");
  };

  const [resultColumnTitle, setResultColumnTitle] = useState("Reports result");
  const [resultType, setResultType] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);

  /**SO Status related */
  const [isFetchingSoAckAndPendingAckVolume, setIsFetchingSoAckAndPendingAckVolume] = useState(true);
  const [soLastAckPendingVolumeResult, setSoLastAckPendingVolumeResult] = useState([]);
  const [isFailedToRetrieveSoAckAndPendingAckVolume, setIsFailedToRetrieveSoAckAndPendingAckVolume] = useState(false);
  const [errorMessageSoAckAndPendingAckVolume, setErrorMessageSoAckAndPendingAckVolume] = useState("");
  const [isFetchingAgingSoList, setIsFetchingAgingSoList] = useState(false);
  const [soAgingListResult, setSoAgingListResult] = useState([]);
  const [isFailedToRetrieveAgingSoList, setIsFailedToRetrieveAgingSoList] = useState();
  const [errorMessageAgingSoList, setErrorMessageAgingSoList] = useState("");

  const columns = [
    {
      isDummyField: true,

      text: "Aging Tag",
      formatter: (cell, row) => {
        return "Aging";
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "sentToMarketDate",
      text: "Age (As of report time)",
      formatter: soAgeFormatter,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "fromParty",
      text: "From",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "toParty",
      text: "To",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "sentToMarketDate",
      text: "Sent to Market Date",
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        return formatDateTimeTypeA(cell, DATE_FORMAT_6);
      },
    },
    {
      dataField: "hubAckDate",
      text: "Hub Ack Date",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "appointmentDate",
      text: "Appointment Date",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "nmi",
      text: "NMI",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soNumber",
      text: "SO Number",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soType",
      text: "SO Type",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soSubType",
      text: "SO Sub Type",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "txnRefNum",
      text: "Txn Ref Num",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
    },
    {
      isDummyField: true,
      text: "Source System",
      headerAlign: "center",
      align: "center",
    },
  ];

  const createParticipantArr = () => {
    // const tenantId = getUserTenantId();

    if (tenantId === TENANT_1001) {
      return [FUEL_TYPE_ELEC];
    } else {
      return [];
    }
  };
  const [tenantFuelTypes, setTenantFuelTypes] = useState(createParticipantArr());
  const [resultTitle, setResultTitle] = useState("");
  const handleServiceOrderStatus = async (fuelType) => {
    setResultTitle(
      <>
        <strong>SO Status - {fuelType}</strong> (as of {getCurrentAuTime().format(DATE_FORMAT_4)}{" "}
        {determineIfAestOrAedt()})
      </>
    );
    log("handleServiceOrderStatus called with " + fuelType);
    fuelType = fuelType.toLowerCase();

    /** Trigger both fetch functionality at the same time */
    await Promise.all([fetchSoAckAndPendingAckVolume(fuelType), fetchAgingSoList(fuelType)]);
  };

  const fetchSoAckAndPendingAckVolume = async (fuelType) => {
    try {
      setIsFetchingSoAckAndPendingAckVolume(true);
      const params = {
        country: getUserCountry(),
        tenantId: getUserTenantId(),
        reportType: "so-ack",
        fuelType,
      };

      let apiResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/dashboard/so-reports`, params);

      if (apiResponse.status === HTTP_STATUS_CODE_200) {
        setSoLastAckPendingVolumeResult(apiResponse.data);
        setIsFailedToRetrieveSoAckAndPendingAckVolume(false);
      } else if (apiResponse.status === HTTP_STATUS_CODE_400) {
        setErrorMessageSoAckAndPendingAckVolume("Error while processing request for SO Last Ack and SO Pending Volume");
        setIsFailedToRetrieveSoAckAndPendingAckVolume(true);
      } else if (apiResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessageSoAckAndPendingAckVolume(
          "Taking too long to process the request for SO Last Ack and SO Pending Volume"
        );
        setIsFailedToRetrieveSoAckAndPendingAckVolume(true);
      } else {
        log("Unhandled http status code");
        setIsFailedToRetrieveSoAckAndPendingAckVolume(true);
      }

      setIsFetchingSoAckAndPendingAckVolume(false);
    } catch (error) {
      log(error);
      setIsFailedToRetrieveSoAckAndPendingAckVolume(true);
      setErrorMessageSoAckAndPendingAckVolume("Error while processing request for SO Last Ack and SO Pending Volume");
      setSoLastAckPendingVolumeResult([{}]);
      setIsFetchingSoAckAndPendingAckVolume(false);
    }
  };

  const fetchAgingSoList = async (fuelType) => {
    try {
      setIsFetchingAgingSoList(true);

      const params = {
        country: getUserCountry(),
        tenantId: getUserTenantId(),
        reportType: "so-aging-list",
        fuelType,
      };

      let apiResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/dashboard/so-reports`, params);

      if (apiResponse.status === HTTP_STATUS_CODE_200) {
        setSoAgingListResult(apiResponse.data);
        setIsFailedToRetrieveAgingSoList(false);
      } else if (apiResponse.status === HTTP_STATUS_CODE_400) {
        setErrorMessageAgingSoList("Error while processing request for SO Aging List");
        setIsFailedToRetrieveAgingSoList(true);
      } else if (apiResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessageAgingSoList("Taking too long to process the request for SO Aging List");
        setIsFailedToRetrieveAgingSoList(true);
      }

      setIsFetchingAgingSoList(false);
    } catch (error) {
      log(error);
      setSoAgingListResult([]);
      setErrorMessageAgingSoList("Error while processing request for SO Aging List");
      setIsFailedToRetrieveAgingSoList(true);
      setIsFetchingAgingSoList(false);
    }
  };

  useEffect(() => {
    handleServiceOrderStatus("elec");
    // setSelectOptions(createSourceSystemFiltersForBootstrapTable(data));
  }, []);
  return (
    <div className="wrapper-div">
      {isFetchingSoAckAndPendingAckVolume ? (
        <div>
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <>
          {isFailedToRetrieveSoAckAndPendingAckVolume ? (
            <div>{errorMessageSoAckAndPendingAckVolume}</div>
          ) : (
            <>
              <div className={`${determineTenantName()}-gradient`}>Last SO Ack Date/Time</div>
              <div className="bottom-div">
                Last Ack was:{" "}
                <strong>{formatDateTimeTypeA(soLastAckPendingVolumeResult[0].lastAckSO, DATE_FORMAT_5)}</strong>
              </div>
              <br></br>
              <div className={`${determineTenantName()}-gradient`}>In Pending SO Ack Volume</div>
              <div className="bottom-div">
                In Pending Acknowledgement: <strong>{soLastAckPendingVolumeResult[0].allPendingSO}</strong>
              </div>
              <br></br>
            </>
          )}
        </>
      )}

      {isFetchingAgingSoList ? (
        <div>
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <>
          {isFailedToRetrieveAgingSoList ? (
            <div>{errorMessageAgingSoList}</div>
          ) : (
            <>
              <div className={`${determineTenantName()}-gradient`}>Aging SO List</div>
              <div className="bottom-div">
                SO Aging: <strong>{soAgingListResult.length}</strong>
                <br></br>
                {soAgingListResult.length > 0 ? (
                  <ToolkitProvider columns={columns} data={soAgingListResult} bootstrap4 exportCSV>
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          classes="search-result-table target-resulting-table"
                          wrapperClasses="search-result-wrapper"
                          headerWrapperClasses={determineTenantName()}
                          pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                          keyField={"soNumber"}
                          condensed={true}
                          striped={true}
                        />
                        <div className="export-csv-button-div-wrapper">
                          <ExportCSVButton {...props.csvProps} className="csv-export-button">
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                ) : null}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ServiceOrderStatusView;
