import React from "react";
import { Col, Spinner, Tab } from "react-bootstrap";
import { determineTenantNamePassId } from "../../../utils/ALL/tenantUtils";

const SearchResultWrapper = ({ tenantId, searchResultTitle, isLoading, children }) => {
  return (
    <Col sm={9} className="search-result-column">
      <Tab.Content className="search-result-tab-content">
        <div className="search-results-title" id={determineTenantNamePassId(tenantId)}>
          {searchResultTitle}
        </div>
        <div className="empty-div">
          {isLoading ? (
            <div className="spinner-div-searching">
              <Spinner animation="border" variant="danger" className="spinner-searching" />
            </div>
          ) : null}

          {children}
        </div>
      </Tab.Content>
    </Col>
  );
};

export default SearchResultWrapper;
