import React from "react";
import ReactDatePicker from "react-datepicker";
import "./DatePicker.css";

const DatePicker = ({
  className,
  selected,
  onChange,
  dateFormat,
  showMonthYearPicker,
  showMonthDropdown,
  showYearDropdown,
}) => {
  return (
    <ReactDatePicker
      className={className}
      selected={selected}
      onChange={onChange}
      dateFormat={dateFormat}
      showMonthYearPicker={showMonthYearPicker}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
    />
  );
};

export default DatePicker;
