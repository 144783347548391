import React, { useState } from "react";
import SearchInputAccordionItem from "./common-components/SearchInputAccordionItem";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import { log } from "../../../utils/ALL/loggingUtils";
import { PROCESS_REQUEST_ID } from "../views/D101ProcessSearchUtils";

const D101ProcessRequestIdSearch = ({
  eventKey,
  setProcessRequestIdSearch,
  setIsLoading,
  setIsFailedToRetrieveData,
  setErrorMessage,
  setSearchType,
}) => {
  const [requestId, setRequestId] = useState("");

  const handleRequestIdSearch = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);
      setSearchType(PROCESS_REQUEST_ID);
      let searchParams = {
        requestId,
      };

      setProcessRequestIdSearch([]);

      log(searchParams);
      const postResponse = await doPost(
        `${process.env.REACT_APP_BASE_API_ENDPOINT}/search/process-requestId`,
        searchParams
      );

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("Error while processing request");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setProcessRequestIdSearch(postResponse.data);
        log(postResponse.data);
        setIsFailedToRetrieveData(false);
        setErrorMessage("");
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setProcessRequestIdSearch([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");
        setProcessRequestIdSearch([]);
        setIsFailedToRetrieveData(true);
      }
      setIsLoading(false);
    } catch (error) {
      log("error in getting NMI details");
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };
  const requestIdSearchProps = {
    eventKey,
    accordionHeaderTitle: "Process Request ID​",
    formLabel: "Process Request ID",
    formControlName: "requestId",
    onChange: setRequestId,
    onButtonClick: handleRequestIdSearch,
    isSearchButtonDisabled: false,
  };
  return (
    <SearchInputAccordionItem
      {...requestIdSearchProps}
    ></SearchInputAccordionItem>
  );
};

export default D101ProcessRequestIdSearch;
