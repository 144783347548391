import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { TENANT_D101, determineTenantName, determineTenantNamePassId } from '../../../utils/ALL/tenantUtils'
import "./SORequestReports.css"
import moment from 'moment-timezone'
import { DATE_FORMAT_1, DATE_FORMAT_3, DATE_FORMAT_6, DATE_FORMAT_8, convertUtcDateTimeToAedtAndFormat, convertUtcDateTimeToAestAndFormat, getCurrentAuTime, getCurrentAuTimeAndFormat, isAuSydneyInDST } from '../../../utils/dateTimeUtils'
import { log } from '../../../utils/ALL/loggingUtils'
import { HTTP_STATUS_CODE_200, HTTP_STATUS_CODE_400, HTTP_STATUS_CODE_504, doPost } from '../../../utils/apiUtils'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DEFAULT_SORTED_3, PAGINATION_OPTIONS_1 } from '../../../utils/bootStrapTableUtils'
import { rowStyler } from '../../SearchResults/NMISearchResults/utils/NMISearchResultUtils'
import CustomSelect from '../components/CustomSelect'
import DatePicker from '../../Common/ReusableComponents/DatePicker/DatePicker'
import { DATE_PICKER_FORMAT_1 } from '../../Common/ReusableComponents/DatePicker/DatePickerUtils'
import LoadingSpinner from '../../Common/ReusableComponents/Spinner/LoadingSpinner'
import { auMasterTxnStatusFormatter } from '../../../utils/AU/auTxnStatusUtils'

const SORequestReports = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [apiData, setApiData] = useState([])
  const FILTER_TYPE_SO_TYPE = "so-type"
  const FILTER_TYPE_STATUS = "ack-status"
  const [soTypeFIlterOptions, setSoTypeFIlterOptions] = useState([]);
  const [ackStatusFilter, setAckStatusFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [customFilterActive, setCustomFilterActive] = useState(false);
  let filterMap = new Map([
    [FILTER_TYPE_SO_TYPE, "All"],
    [FILTER_TYPE_STATUS, "All"],
  ]);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   fetchData()
  // }, [])

  useEffect(() => {
    fetchData()
  }, [startDate, endDate])


  const fetchData = async () => {

    try {
      const params = {
        startDate,
        endDate
      }
      setIsLoading(true);
      setCustomFilterActive(false)
      // setIsFailedToRetrieveData(false);

      log(JSON.stringify(params))

      const postResponse = await doPost(`https://7r1c8yqcv9.execute-api.ap-southeast-2.amazonaws.com/dev/portal/so-request`, params);
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_400) {
        // setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setApiData(postResponse.data)
        const soTypeFilters = createSoTypeFilter(postResponse.data);
        setSoTypeFIlterOptions(soTypeFilters)
        const ackStatusFilter = createAckStatusFilter(postResponse.data)
        setAckStatusFilter(ackStatusFilter)
        // setIsFailedToRetrieveData(false);


      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        // setErrorMessage("Taking too long to process the request");
        // setMessageId("");
        // setIsFailedToRetrieveData(true);
      } else {
        // log("Unhandled http status code");
        // setMessageId("");
        // setIsFailedToRetrieveData(true);
      }

      setIsLoading(false);
    } catch (error) {
      log(`error while getting data`);
      log(error);
      //   setErrorMessage("Error while retrieving data");
      //   setIsFailedToRetrieveData(true);
      //   setIsLoading(false);
      // }



    }
  }
  const handleSoTypeFilter = (filterValue, dataToFilter) => {
    if (filterValue === "All") {
      /** no need to do any filtering */
      return dataToFilter;
    } else {
      const filteredDataForSoType = dataToFilter.filter((value) => {

        if (value.type === filterValue) {
          return true;
        }
      });
      return filteredDataForSoType;
    }
  };

  const handleAckStatusFilter = (filterValue, dataToFilter) => {


    if (filterValue === "All") {
      /** no need to do any filtering */
      return dataToFilter;
    } else {
      const filteredDataForAckStatus = dataToFilter.filter((value) => {

        if (value.ackStatus === filterValue) {
          return true;
        }
      });

      return filteredDataForAckStatus;
    }
  };

  const handleFiltering = (filterType, filterValue) => {
    log("adjusting filterMap");
    filterMap.set(filterType, filterValue);

    let newData = [];
    let isFirstIteration = true;
    filterMap.forEach((value, key) => {
      if (isFirstIteration) {
        newData = apiData;
        isFirstIteration = false;
      }

      switch (key) {
        case FILTER_TYPE_SO_TYPE:
          newData = handleSoTypeFilter(value, newData);
          break;
        case FILTER_TYPE_STATUS:
          newData = handleAckStatusFilter(value, newData);
          break;
        default:
          log("Filter type not recognized");
          break;
      }
      log(newData);

      setFilteredData(newData);
      setCustomFilterActive(true);
    });
  };

  const createSoTypeFilter = (currentData) => {
    let soTypeSet = new Set();
    currentData.map((data, index) => {
      soTypeSet.add(data.type);
    });

    let soTypeArray = [];
    soTypeSet.forEach((soType) => {
      soTypeArray = [...soTypeArray, { value: soType, label: soType }];
    });

    return soTypeArray;
  };

  const createAckStatusFilter = (currentData) => {
    let ackStatusSet = new Set();
    currentData.map((data, index) => {
      ackStatusSet.add(data.ackStatus);
    });

    let ackStatusArray = [];
    ackStatusSet.forEach((soType) => {
      ackStatusArray = [...ackStatusArray, { value: soType, label: soType }];
    });

    return ackStatusArray;
  };

  const config = {
    align: "center",
    headerAlign: "center",
  }

  const columns = [
    {
      dataField: "serviceOrderNumber",
      text: "SO",
      ...config
    },
    {
      dataField: "meterPointID",
      text: "NMI",
      ...config
    },
    {
      dataField: "dateCreated",
      text: "Transaction Date",
      formatter: (cell, row) => {

        if (isAuSydneyInDST()) {
          return convertUtcDateTimeToAedtAndFormat(cell, DATE_FORMAT_6);
        } else {
          return convertUtcDateTimeToAestAndFormat(cell, DATE_FORMAT_6);
        }

      },
      ...config
    },
    {
      dataField: "appointmentDate",
      text: "Scheduled Date",
      ...config
    },
    {
      dataField: "type",
      text: "SO Type",
      ...config
    },
    {
      dataField: "soSubType",
      text: "SO Sub Type",
      ...config
    },
    {
      dataField: "soAction",
      text: "SO Action",
      ...config
    },
    {
      dataField: "sender",
      text: "From ID",
      ...config
    },
    {
      dataField: "receiver",
      text: "To ID",
      ...config
    },

    {
      dataField: "status",
      text: "Status",
      formatter: auMasterTxnStatusFormatter,
      ...config
    },
  ]



  return (
    <Container fluid className="master-txn-container-wrapper">
      <Row >
        <Col className="search-results-title so-report-gradient" id={determineTenantNamePassId(TENANT_D101)}>
          <strong>SO Status Report</strong>
        </Col>
      </Row>
      <Row style={{ padding: "15px", backgroundColor: "#F2F4F6" }}>
        <Col>
          <Row>
            <Col className="search-results-title so-report-gradient" id={determineTenantNamePassId(TENANT_D101)}><strong>Service Order Requests - Elec</strong></Col>
          </Row>

          <Row>
            <Col>
              <CustomSelect label="Select Type"
                filterType={FILTER_TYPE_SO_TYPE}
                options={soTypeFIlterOptions}
                onChange={handleFiltering}
                defaults={[{ value: "All", label: "All" }]}
              >
              </CustomSelect>
            </Col>
            <Col>
              <div className="custom-select-div-wrapper">
                <Form.Label>Transaction Date (From):</Form.Label>

                <DatePicker
                  className="historical-date-pick"
                  selected={startDate}
                  onChange={(startDate) => {
                    setStartDate(startDate)
                  }}
                  dateFormat={DATE_PICKER_FORMAT_1}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                ></DatePicker>
              </div>
            </Col>
            <Col>
              <div className="custom-select-div-wrapper">
                <Form.Label>Transaction Date (To):</Form.Label>

                <DatePicker
                  className="historical-date-pick"
                  selected={endDate}
                  onChange={(endDate) => {
                    console.log("change on endDate: " + endDate);

                    setEndDate(endDate)
                  }}
                  dateFormat={DATE_PICKER_FORMAT_1}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                ></DatePicker>
              </div>
            </Col>
            <Col>
              <CustomSelect label="Select Type"
                filterType={FILTER_TYPE_STATUS}
                options={ackStatusFilter}
                onChange={handleFiltering}
                defaults={[{ value: "All", label: "All" }]}
              >
              </CustomSelect>
            </Col>
          </Row>


          {isLoading ? <LoadingSpinner></LoadingSpinner> : <>
            <Row>
              <Col className='result-number mt-2'>
                <div style={{ backgroundColor: "white" }}>
                  {customFilterActive ? filteredData.length : apiData.length}
                </div>
              </Col>
            </Row>
            <Row>
              <Col >
                <div style={{ backgroundColor: "white", paddingLeft: "10px", paddingRight: "10px" }}>
                  <BootstrapTable
                    keyField="dateCreated"
                    columns={columns}
                    data={customFilterActive ? filteredData : apiData}
                    pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                    wrapperClasses="associated-txn-wrapper"
                    rowStyle={rowStyler}
                    classes="associated-transactions-table"
                    defaultSorted={DEFAULT_SORTED_3}
                    headerWrapperClasses={determineTenantName()}
                  />
                </div>
              </Col>
            </Row>
          </>}
        </Col>
      </Row>
      {/* <Row>
        <Col className="search-results-title so-report-gradient" id={determineTenantNamePassId(TENANT_D101)}>
          SO Status Report
        </Col>
      </Row>
      <Row>
        <Row className='sord-request-row'>
          <Col className="search-results-title so-report-gradient" id={determineTenantNamePassId(TENANT_D101)}>
            Service Order Requests - Elec
          </Col>
          <Row>
            <Col>
              <CustomSelect label="Select Type"
                filterType={FILTER_TYPE_SO_TYPE}
                options={soTypeFIlterOptions}
                onChange={handleFiltering}
                defaults={[{ value: "All", label: "All" }]}
              >
              </CustomSelect>
            </Col>
            <Col>
              <div className="custom-select-div-wrapper">
                <Form.Label>Transaction Date</Form.Label>

                <DatePicker
                  className="historical-date-pick"
                  selected={startDate}
                  onChange={(startDate) => {
                    setStartDate(startDate)
                  }}
                  dateFormat={DATE_PICKER_FORMAT_1}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                ></DatePicker>
              </div>
            </Col>
            <Col>
              <CustomSelect label="Select Type"
                filterType={FILTER_TYPE_STATUS}
                options={ackStatusFilter}
                onChange={handleFiltering}
                defaults={[{ value: "All", label: "All" }]}
              >
              </CustomSelect>
            </Col>
          </Row>
        </Row>

        {isLoading ? <LoadingSpinner></LoadingSpinner> : <>
          <Row>
            <Col className='result-number'>
              {customFilterActive ? filteredData.length : apiData.length}
            </Col>
          </Row>
          <Row>
            <Col >
              <BootstrapTable
                keyField="dateCreated"
                columns={columns}
                data={customFilterActive ? filteredData : apiData}
                pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                wrapperClasses="associated-txn-wrapper"
                rowStyle={rowStyler}
                classes="associated-transactions-table"
                defaultSorted={DEFAULT_SORTED_3}
                headerWrapperClasses={determineTenantName()}
              />
            </Col>
          </Row></>}
      </Row> */}
    </Container>
  )
}

export default SORequestReports