import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const Registers = ({ data }) => {
  // console.log(data);

  const registerConfiguration = JSON.parse(data.registerConfiguration);
  // console.log(registerConfiguration);

  const firstColumnMap = new Map([
    // ["Sequence number:", data.sequenceNumber],
    // ["Maintained At:", data.maintainedAt],
    // ["Created At:", data.createdAt],
    ["Serial Number:", data.serialNumber || "-"],
    ["Register ID:", registerConfiguration?.RegisterID?.[0]] || "-",
    ["Status:", data.status || "-"],
    [
      "Network Tariff Code:",
      registerConfiguration?.NetworkTariffCode?.[0] || "-",
    ],
    [
      "Network Additional Information:",
      registerConfiguration?.NetworkAdditionalInformation?.[0] || "-",
    ],
    ["Unit of Measure:", registerConfiguration?.UnitOfMeasure?.[0] || "-"],
    ["Time of Day:", registerConfiguration?.TimeOfDay?.[0] || "-"],
  ]);
  const secondColumnMap = new Map([
    ["Row Status:", registerConfiguration?.Status?.[0] || "-"],
    // ["Last Seen:", data.lastSeen],
    // ["Inactivated for FRMPs:", data.inactivatedForFrmps],
    ["Dial Format:", registerConfiguration?.DialFormat?.[0] || "-"],
    ["Multiplier:", registerConfiguration?.Multiplier?.[0] || "-"],
    ["Suffix:", registerConfiguration?.Suffix?.[0] || "-"],
    ["Controlled Load:", registerConfiguration?.ControlledLoad?.[0] || "-"],
    // ["Consumption Type:", data.consumptionType],
    // ["Demand 1:", data.demand],
    // ["Demand 2:", data.demand],
  ]);
  return (
    <Row>
      <Col>
        <Table>
          {Array.from(firstColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
      <Col>
        <Table>
          {Array.from(secondColumnMap).map(([key, value]) => (
            // <tr key={key}>
            //   <td>
            //     <strong>{key}</strong> {value}
            //   </td>
            // </tr>
            <tr key={key}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>{value}</td>
            </tr>
          ))}
        </Table>
      </Col>
    </Row>
  );
};

export default Registers;
