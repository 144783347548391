import moment from "moment-timezone";
import {
  TENANT_1001,
  TENANT_1002,
  TENANT_1005,
  TENANT_D101,
  TENANT_d101,
} from "../../../../utils/ALL/tenantUtils";
import { auMasterTxnStatusFormatter } from "../../../../utils/AU/auTxnStatusUtils";
import { FUEL_TYPE_ELEC, FUEL_TYPE_GAS } from "../../../../utils/AU/constantAU";
import {
  DATE_CREATED,
  DATE_CREATED_AEDT,
  DATE_CREATED_AEST,
  DATE_FORMAT_1,
  DATE_FORMAT_3,
  DATE_FORMAT_7,
  DATE_FORMAT_8,
  convertUtcDateTimeToAedtAndFormat,
  convertUtcDateTimeToAestAndFormat,
  formatDateTimeTypeA,
  isAuSydneyInDST,
} from "../../../../utils/dateTimeUtils";
import { SEARCH_DATA_TYPE_HISTORICAL } from "../../../Search/AU/AUSearchViewUtils";

export const rowStyler = (row, rowIndex) => {
  row.index = rowIndex;
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#E7E7E7";
  } else {
    style.backgroundColor = "#FFFFFF";
  }
  style.borderTop = "none";

  return style;
};

export const auMeterpointIdColumnFormatter = (formatExtraData) => {
  const searchResultFuelType = formatExtraData;
  return searchResultFuelType === FUEL_TYPE_ELEC.toLowerCase() ? "NMI" : "MIRN";
};

export const dateCreatedFormatter = (cell, row, rowIndex, formatExtraData) => {
  /** Uses if statement, as other tenants might use the same url */
  const tenantId = formatExtraData;
  const date = cell;
  if (tenantId === TENANT_1001) {
    return formatDateTimeTypeA(cell, DATE_FORMAT_3);
  } else if (
    tenantId === TENANT_1002 ||
    tenantId === TENANT_D101 ||
    tenantId === TENANT_d101
  ) {
    if (isAuSydneyInDST()) {
      return convertUtcDateTimeToAedtAndFormat(cell, DATE_FORMAT_3);
    } else {
      return convertUtcDateTimeToAestAndFormat(cell, DATE_FORMAT_3);
    }
  } else if (tenantId === TENANT_1005) {
    let formattedDateTime = "";
    //TODO: check if the date utils have the same functionality
    if (moment(date).isValid()) {
      //for new format

      formattedDateTime = moment(date)
        .utc(false)
        .add(10, "hours")
        .format(DATE_FORMAT_7)
        .toString();
    } else if (moment(date, DATE_FORMAT_8).isValid()) {
      //for the old format
      formattedDateTime = moment(date, DATE_FORMAT_8)
        .utc(true)
        .add(10, "hours")
        .format(DATE_FORMAT_7)
        .toString();
    } else {
      formattedDateTime = date;
    }
    return formattedDateTime;
  } else {
    return cell;
  }
};

export const dateCreatedColumnNameFormatter = (tenantId) => {
  /** Uses if statement, as other tenants might use the column header */

  if (
    tenantId === TENANT_1002 ||
    tenantId === TENANT_D101 ||
    tenantId === TENANT_d101
  ) {
    if (isAuSydneyInDST()) {
      return DATE_CREATED_AEDT;
    } else {
      /**Tenants that is always AEST AU timezone */
      return DATE_CREATED_AEST;
    }
  } else if (tenantId === TENANT_1005) {
    return DATE_CREATED_AEST;
  } else {
    /**Tenants that is not define */
    return DATE_CREATED;
  }
};

export const auMarketFormatter = (cell, row, rowIndex, formatExtraData) => {
  const searchResultFuelType = formatExtraData;
  if (searchResultFuelType === FUEL_TYPE_ELEC.toLowerCase()) {
    return "NEM";
  } else if (searchResultFuelType === FUEL_TYPE_GAS.toLowerCase()) {
    return row.market;
  } else {
    return "";
  }
};

const masterTransactionIdOnClick = (cell, url) => {
  return (
    <a
      onClick={() => {
        window.open(url);
      }}
    >
      {cell}
    </a>
  );
};

export const masterTransactionIdFormatter = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  /** Uses if statement, as other tenants might use the same url */
  const { tenantId, searchDataType, searchResultFuelType } = formatExtraData;

  if (tenantId === TENANT_1001 || tenantId === "D101" || tenantId === "d101") {
    let url = `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&sender=${row.sender}&nmi=${row.meterPointID}&fuel=${searchResultFuelType}&crcode=${row.changeReasonCode}`;
    if (searchDataType.toLowerCase() === SEARCH_DATA_TYPE_HISTORICAL) {
      url =
        url +
        `&dbName=historical&txnGroup=${
          row.txnGroup
        }&dateInitiated=${formatDateTimeTypeA(
          row.dateInitiated,
          DATE_FORMAT_1
        )}`;
    }

    return masterTransactionIdOnClick(cell, url);
  } else if (tenantId === TENANT_1002) {
    return masterTransactionIdOnClick(
      cell,
      `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&sender=${row.sender}&nmi=${row.meterPointID}&crcode=${row.changeReasonCode}`
    );
  } else if (tenantId === TENANT_1005) {
    return masterTransactionIdOnClick(
      cell,
      `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&dateCreated=${row.dateInitiated}&txnType=${row.masterTxnType}&sender=${row.sender}&nmi=${row.meterPointID}&fuel=elec`
    );
  } else {
    return cell;
  }
};
export const masterTxnTypeFormatter = (
  cell,
  row,
  rowIndex,
  formatExtraData,
  enableUnsolicitedColumn
) => {
  const { tenantId } = formatExtraData;

  if (
    tenantId === TENANT_1001 ||
    (tenantId === TENANT_D101 && cell === "Change Request") ||
    cell === "ChangeRequest"
  ) {
    return row.changeReasonCode;
  }
  if (
    tenantId === TENANT_1001 ||
    (tenantId === TENANT_D101 && cell === "Service Order") ||
    cell === "ServiceOrderRequest"
  ) {
    return row.type;
  } else {
    return cell;
  }
};
export const nmiSearchResultColumns = (
  tenantId,
  searchDataType,
  searchResultFuelType,
  hideUnsolicitedColumn = true,
  hideSONumberColumn = true
) => {
  return [
    {
      dataField: "masterTxnId",
      text: "Master Transaction ID",
      formatter: masterTransactionIdFormatter,
      classes: "master-txn-id-td",
      csvExport: true,
      formatExtraData: { tenantId, searchDataType, searchResultFuelType },
    },
    {
      dataField: "meterPointID",

      text: auMeterpointIdColumnFormatter(searchResultFuelType),
    },
    {
      dataField: "dateInitiated",
      text: dateCreatedColumnNameFormatter(tenantId),
      formatter: dateCreatedFormatter,
      sort: true,
      formatExtraData: tenantId,
    },
    {
      dataField: "abc",
      text: "Market",
      formatter: auMarketFormatter,
      formatExtraData: searchResultFuelType,
    },
    {
      dataField: "masterTxnType",
      text: "Transaction Type",
      formatter: masterTxnTypeFormatter,
      formatExtraData: { tenantId },
    },
    {
      dataField: "serviceOrderNumber",
      text: "Service Order Number",
      hidden: hideSONumberColumn,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: auMasterTxnStatusFormatter,
    },

    {
      dataField: "sender",
      text: "Source",
    },
  ];
};
