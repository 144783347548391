const MASTER_DATA = "masterdata";
const DATA_STREAM = "datastream";
const METER = "meter";
const ROLE_ASSIGNMENTS = "roleassignments";

export const createStandingDataResult = (jsonResponse) => {
  let standingData = {
    masterData: {},
    dataStream: [],
    meter: {
      meterDetails: [],
      registerConfigurations: [],
    },
    roleAssignments: {},
  };

  jsonResponse.map((data) => {
    const dataType = data.datatype;

    if (dataType === MASTER_DATA) {
      return (standingData.masterData = data);
    } else if (dataType === ROLE_ASSIGNMENTS) {
      return (standingData.roleAssignments = data);
    } else if (dataType.includes(METER)) {
      if (data.registerConfiguration) {
        return (standingData.meter.registerConfigurations = [
          ...standingData.meter.registerConfigurations,
          data,
        ]);
      } else {
        return (standingData.meter.meterDetails = [
          ...standingData.meter.meterDetails,
          data,
        ]);
      }
      // return (standingData.meter = [...standingData.meter, data]);
    } else if (dataType.includes(DATA_STREAM)) {
      return (standingData.dataStream = [...standingData.dataStream, data]);
    } else {
      console.log(`datatype: ${dataType} is not handled`);
    }
  });

  return standingData;
};
