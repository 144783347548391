import React from "react";
import { Row, Tab } from "react-bootstrap";

const SearchViewWrapper = ({ children }) => {
  return (
    <div className="search-form-wrapper">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="search-row mt-3" style={{ marginBottom: "60px" }}>
          {children}
        </Row>
      </Tab.Container>
    </div>
  );
};

export default SearchViewWrapper;
