import React from "react";
import { Form } from "react-bootstrap";
import "./CustomSelect.css";
function CustomSelect({ label, options, defaults, onChange, filterType }) {
  return (
    <div className="custom-select-div-wrapper">
      <Form.Label>{label}</Form.Label>
      <Form.Select
        name="meterpointId"
        onChange={(e) => {
          onChange(filterType, e.target.value);
        }}
      >
        <option key={defaults[0].value} value={defaults[0].value}>
          {defaults[0].label}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}

export default CustomSelect;
