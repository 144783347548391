import { getUserTenantId } from "./localStorageUtils";

export const COUNTRY_AU = "AU";
export const COUNTRY_NZ = "NZ";

export const TENANT_1001 = "1001";
export const TENANT_1002 = "1002";
export const TENANT_1003 = "1003";
export const TENANT_1004 = "1004";
export const TENANT_1005 = "1005";
export const TENANT_d101 = "d101";
export const TENANT_D101 = "D101";
export const determineTenantName = () => {
  let tenantId = getUserTenantId();

  return determineTenantNamePassId(tenantId);
};

export const determineTenantNamePassId = (tenantId) => {
  switch (tenantId) {
    case TENANT_1001:
      return "origin";
    case TENANT_1002:
      return "nectr";
    case TENANT_1003:
      return "octo";
    case TENANT_1005:
      return "eql";
    case TENANT_d101:
      return "essential";
    case TENANT_D101:
      return "essential";
    default:
      return "default-tenant";
  }
};

export const TENANT_ID_COUNTRY_MAP = new Map([
  [TENANT_1001, COUNTRY_AU],
  [TENANT_1002, COUNTRY_AU],
  [TENANT_1003, COUNTRY_NZ],
  [TENANT_1005, COUNTRY_AU],
  [TENANT_d101, COUNTRY_AU],
  [TENANT_D101, COUNTRY_AU],
]);
